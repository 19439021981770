import {createStore, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import * as home from './reducer';
import * as header from '../shared/header/reducer';

let store = createStore(
  combineReducers({...header, ...home}),
  applyMiddleware(thunk)
);

export default store;