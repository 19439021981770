import React, {Component} from "react";
import {connect} from "react-redux";

import style from '../../assets/css/shared/modal.module.scss';

import closeUrl from '../../assets/images/home/modal_icon_close.png';
import * as action from "../../store/shared/header/action";

const iconArr = ['modal_icon01','modal_icon02','modal_icon03'];
const iconUrlArr = iconArr.map(item=>require("../../assets/images/home/" + item + ".png"));
const texts=[
    '描述您在产品使用过程中存在的问题，平台将认真核查并答疑解惑',
    '留下您对产品的意见反馈，平台将据此不断优化产品功能',
    '咨询产品服务订购相关问题，平台专员将会及时与您取得联系'
];
const tip=[
    '输入不能为空',
    '建议已提交，感谢您的支持！',
    '提交失败'
]

class Modal extends Component {
    constructor(props){
        super(props);
        this.state = {
            isShow: true
        }
    }

    submit(e){
        let _this = this;
        let state=this.props.state;
        let value =  state.contact.replace(/[^-|\d]/g, '');
        if(state.userName!==''&&state.contact!==''&&state.advice!==''){
            if(state.userName.length>100){
                alert('昵称不能超过100')
                return false
            }
            if(state.contact.length>100){
                alert('联系方式不能超过100')
                return false
            }
            else if(!((/^([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/g.test(state.contact)) || (/^1[3456789]\d{9}$/.test(state.contact)) ||  (/^((\+86)|(86))?(1)\d{10}$/.test(value)) || (/^0[0-9-]{10,13}$/.test(value)))){
                alert("邮箱地址或联系电话格式不正确，请重新输入")
                return false
            }
            if(state.advice.length>200){
                alert('问题或建议不能超过200')
                return false
            }
            _this.props.submit(e,state);
        }
        else{
            _this.props.handleKeyValue('isAddUserAdviceSuccess',0);
        }
    }

    render() {
        let state = this.props.state;
        let tipCls = '';
        if (state.isAddUserAdviceSuccess === -1) {
            tipCls = 'hide';
        } else if (state.isAddUserAdviceSuccess === 1) {
            tipCls = 'success show';
        } else {
            tipCls = 'alert show';
        }
        return (
            <div className={'modal ' + style["modal"] + " fade" + " " + (this.props.state.isShow?"in":'')}>
                <div className={'modal-dialog ' + style["modal-dialog"]} role="document">
                    <img className={style['close']} src={closeUrl} onClick={()=>this.props.handleKeyValue('isShow',false)} />
                    <div className={'modal-body ' + style["modal-body"]}>
                        <div className={style['left']}>
                            <div className={style['title']}>
                                联系我们
                            </div>
                            <ul>
                                {
                                    iconUrlArr.map((item,index)=>{
                                        return (
                                            <li key={index}>
                                                <img src={item.default} />
                                                <p>
                                                    {texts[index]}
                                                </p>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className={style['right']}>
                            <div className={'form-group '+style['form-group']}>
                                <input className={'form-control'} value={state.userName} maxLength={100} placeholder={'昵称'} onChange={(e)=>this.props.handleValueChange(e,'userName')}/>
                                <span className={style['emphasis']}>*</span>
                            </div>
                            <div className={'form-group '+style['form-group']}>
                                <input className={'form-control'} value={state.contact} maxLength={100} placeholder={'邮箱地址/联系电话'} onChange={(e)=>this.props.handleValueChange(e,'contact')}/>
                                <span className={style['emphasis']}>*</span>
                            </div>
                            <div className={'form-group '+style['form-group']}>
                                <textarea className={'form-control'} value={state.advice} maxLength={200} placeholder={'描述您的问题或建议～'} onChange={(e)=>this.props.handleValueChange(e,'advice')}/>
                                <span className={style['emphasis']}>*</span>
                            </div>
                            <div className={'form-group '+style['form-group']+' '+style['bottom']}>
                                <div className={'btn '+style['btn-submit']} onClick={(e)=>this.submit(e,state)}>提交</div>
                                <div className={'btn '+style['btn-reset']} onClick={()=>this.props.handleClear()}>清除</div>
                            </div>
                            <p className={style['tip'] + ' ' + (tipCls)}>
                                {
                                    tip[state.isAddUserAdviceSuccess]
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

//需要渲染什么数据
const mapStateToProps = (state) => {
    return {
        state: state.header
    }
}

//需要触发什么行为
function mapDispatchToProps(dispatch) {
    return {
        handleKeyValue: (key,value)=>dispatch(action.handleKeyValue(key,value)),
        handleValueChange: (e,key)=>dispatch(action.handleValueChange(e,key)),
        submit: (e, state)=>dispatch(action.submit(e,state)),
        handleClear: ()=>dispatch(action.handleClear())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)