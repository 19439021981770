import * as type from "./action-type";
import envconfig from "../../envconfig/envconfig"

const themeListMirrorImgArr=[
    'img01','img02','img03'
];
const themeListMirrorImgPathArr=themeListMirrorImgArr.map(item=>require("../../assets/images/home/" + item + ".png"))
const userDocUrl = 'doc/用户使用手册-经济社会大数据研究平台.pdf';
const userVideoUrl = 'video/操作视频.mp4';
const publicizeUrl = './publicize';

let _state = {
    //版本
    version: '',

    //banner轮播
    interval: 4000,
    step: 1,
    speed: 400,
    bannerList: [],
    cloneBannerList:[],
    timer: null,

    showAiTip: false, // 智能分析提示框

    //快捷入口
    entryList:[],
    //详情页url
    pageUrl:{
        "课题研究":"./subjectStudy",
        "统计播报":"./statisticFocus",
        "用户报告":"./userReport",
        "年鉴更新":"./yearBook/single?id=",
        "统计资料更新":"./yearBook/single?id=",
        "统计资讯":"./statisticalInfo/index?ky=GDP",
        "统计资讯详情":"./statisticalInfo/detail?fileId=",
        "中国指数":"./businessIndex/index",
        "进度数据发布":"./datapublish/month",
        "指标数值检索":"./valueSearch/index?ky=GDP",
        "年度数据分析":"./yearData",
        "数据智能分析平台":envconfig.dsUrl,
        // "进度数据发布更新":"/datapublish/monthResult?sid="
    },
    //主题列表
    themeList:[],
    //镜像版
    themeListMirror: [
        {
            picPath:themeListMirrorImgPathArr[0],
            resume:'统计资料',
            contents: '本平台运用先进的数据加工处理技术，实现由权威纸质统计年鉴(资料)到电子化表格的高质量转化。简化用户数据查询流程，缩短数据获取时间。',
            themeEntryList:[
                [
                    {
                        name: '统计年鉴',
                        url: './yearBook?type=type&code=A'
                    },
                    {
                        name: '分析报告',
                        url: './yearBook?type=type&code=B'
                    },
                    {
                        name: '资料汇编',
                        url: './yearBook?type=type&code=C'
                    },
                ],
                [
                    {
                        name: '调查资料',
                        url: './yearBook?type=type&code=D'
                    },
                    {
                        name: '普查资料',
                        url: './yearBook?type=type&code=E'
                    },

                    {
                        name: '统计摘要',
                        url: './yearBook?type=type&code=F'
                    }
                ]
            ]
        },
        {
            picPath:themeListMirrorImgPathArr[1],
            resume:'数据分析',
            contents: '实现了不同资料，不同卷册，不同章节间的统计指标的自由组配和统计口径的统一处理，只需选择“地区+指标+时间”，便可查询跨表格，章节的统计数据。',
            themeEntryList:[
                [
                    {
                        name: '年度数据分析',
                        url: './yearData'
                    },
                    {
                        name: '进度数据分析',
                        url: './seasonOrMonth'
                    }
                ],
                [
                    {
                        name: '国际数据分析',
                        url: './internationalData'
                    },
                    {
                        name: '专题数据分析',
                        url: './themeAnalysis/analysis'
                    }
                ]
            ]
        },{
            picPath:themeListMirrorImgPathArr[2],
            resume:'决策支持',
            contents: '深度数据挖掘算法，实现一站式数据查询，分析和决策服务。功能灵活，易用，无需下载，便捷高效地为用户提供专业的决策支持。',
            themeEntryList:[
                [
                    {
                        name: '相关分析',
                        url: './decision/methods/regressionAnalysis?type=year&id=&dcode=&zcode='
                    },
                    {
                        name: '统计预测',
                        url: './decision/methods/timeSeriesTrendPredict?type=year&id=&dcode=&zcode='
                    }
                ],
                [
                    {
                        name: '科学评价',
                        url: './decision/methods/ahp?type=year&id=&dcode=&zcode='
                    },
                    {
                        name: '决策模型',
                        url: './decision/methods/zoneDecision?type=year&dcode=&zcode='
                    }
                ]
            ]
        }
    ],

    //最新上线
    newOnline:[],
    isAddUserAdvice:false,

    //用户列表轮播
    intervalCustomer: 4000,
    stepCustomer: 1,
    speedCustomer: 400,
    customerList:[
        [
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            }
        ],
        [
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            }
        ],
        [
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            }
        ]
    ],
    cloneCustomerList:[
        [
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            }
        ],
        [
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            }
        ],
        [
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            }
        ],
        [
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            }
        ],
        [
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            },
            {
                url:''
            }
        ]
    ],

    userDocUrl: userDocUrl,//用户使用手册
    userVideoUrl: userVideoUrl,//操作视频
    publicizeUrl: publicizeUrl,//宣传页
    hotTopic:[
        {
            title:"中国人口普查数据库",
            url:"https://data.cnki.net/npcd"
        },
        {
            title:"中国城市统计数据库(进度数据版)",
            url:"https://citydata.cnki.net/"
        }
    ],//热点专题
    searchList:[
        {
            title:'统计指标',
            url:'./valueSearch/index?ky='
        },
        {
            title:"统计资料",
            url:'./statisticalData/index?ky='
        },
        {
            title:"条目题名",
            url:'./itemSearch/index?ky='
        },

        {
            title:'统计资讯',
            url:'./statisticalInfo/index?ky='
        }
    ],//检索下拉框
    checkedSearch:{
        title:"统计指标",
        url:'./valueSearch/index?ky='
    },
    isFocus:false,
    kd:'',
    isPanelShow:false,
    dataCount:{},
    isLogin:false,//是否登录
    isIndicateListShow:false,
    indicateList:[],
    areas:[
        {
            AreaName:'北京市',
            PYName:'beijing',
            DCode: 'D01'
        },
        {
            AreaName:'天津市',
            PYName:'tianjin',
            DCode: 'D02'
        },
        {
            AreaName:'河北省',
            PYName:'hebei',
            DCode: 'D03'
        },
        {
            AreaName:'山西省',
            PYName:'shanxi',
            DCode: 'D04'
        },
        {
            AreaName:'内蒙古自治区',
            PYName:'neimenggu',
            DCode: 'D05'
        },
        {
            AreaName:'辽宁省',
            PYName:'liaoning',
            DCode: 'D06'
        },
        {
            AreaName:'吉林省',
            PYName:'jilin',
            DCode: 'D07'
        },
        {
            AreaName:'黑龙江省',
            PYName:'heilongjiang',
            DCode: 'D08'
        },
        {
            AreaName:'上海市',
            PYName:'shanghai',
            DCode: 'D09'
        },
        {
            AreaName:'江苏省',
            PYName:'jiangsu',
            DCode: 'D10'
        },
        {
            AreaName:'浙江省',
            PYName:'zhejiang',
            DCode: 'D11'
        },
        {
            AreaName:'安徽省',
            PYName:'anhui',
            DCode: 'D12'
        },
        {
            AreaName:'福建省',
            PYName:'fujian',
            DCode: 'D13'
        },
        {
            AreaName:'江西省',
            PYName:'jiangxi',
            DCode: 'D14'
        },
        {
            AreaName:'山东省',
            PYName:'shandong',
            DCode: 'D15'
        },
        {
            AreaName:'河南省',
            PYName:'henan',
            DCode: 'D16'
        },
        {
            AreaName:'湖北省',
            PYName:'hubei',
            DCode: 'D17'
        },
        {
            AreaName:'湖南省',
            PYName:'hunan',
            DCode: 'D18'
        },
        {
            AreaName:'广东省',
            PYName:'guangdong',
            DCode: 'D19'
        },
        {
            AreaName:'广西壮族自治区',
            PYName:'guangxi',
            DCode: 'D20'
        },
        {
            AreaName:'海南省',
            PYName:'hainan',
            DCode: 'D21'
        },
        {
            AreaName:'重庆市',
            PYName:'chongqing',
            DCode: 'D22'
        },
        {
            AreaName:'四川省',
            PYName:'sichuan',
            DCode: 'D23'
        },
        {
            AreaName:'贵州省',
            PYName:'guizhou',
            DCode: 'D24'
        },
        {
            AreaName:'云南省',
            PYName:'yunnan',
            DCode: 'D25'
        },
        {
            AreaName:'西藏自治区',
            PYName:'xizang',
            DCode: 'D26'
        },
        {
            AreaName:'陕西省',
            PYName:'shanxi',
            DCode: 'D27'
        },
        {
            AreaName:'甘肃省',
            PYName:'gansu',
            DCode: 'D28'
        },
        {
            AreaName:'青海省',
            PYName:'qinghai',
            DCode: 'D29'
        },
        {
            AreaName:'宁夏回族自治区',
            PYName:'ningxia',
            DCode: 'D30'
        },
        {
            AreaName:'新疆维吾尔自治区',
            PYName:'xinjiang',
            DCode: 'D31'
        },
    ],
}

export const home=(state = _state , action = {}) => {
    switch (action.type) {
        case type.Get_Version:
            state.version=action.data;
            return {...state};
        case type.Get_EntryList:
            state.entryList=action.data;
            return {...state};
        case type.SHOW_MODAL:
            state[[action.key]]=action.value;
            return {...state};
        case  type.GET_BANNERLIST:
            state.bannerList = action.data;
            state.cloneBannerList = action.cloneData;
            return {...state};
        case type.Get_DataCount:
            state.dataCount = action.data;
            return {...state}
        case type.SCROLL:
            state.step++;
            return {...state};
        case type.SCROLL_Customer:
            state.stepCustomer++;
            return {...state};
        case type.SCROLL_INIT:
            state.step=0;
            state.speed=0;
            return {...state}
        case type.SCROLL_INIT_Customer:
            state.stepCustomer=0;
            state.speedCustomer=0;
            return {...state}
        case type.RE_SCROLL:
            state.step=1;
            state.speed=_state.speed;
            return {...state};
        case type.RE_SCROLL_Customer:
            state.stepCustomer=1;
            state.speedCustomer=_state.speedCustomer;
            return {...state};
        case type.SCROLL_TO:
            state.step=action.toStep;
            return {...state}
        case type.Get_NewOnline:
            state.newOnline=action.data;
            return {...state}
        case type.Get_ThemesList:
            state.themeList=action.data;
            return {...state}
        case type.Add_UserAdvice:
            state.isAddUserAdvice=action.data;
            return {...state}
        case type.Get_LoginStatus:
            state.isLogin = action?.data?.online;
            return {...state}
        case type.Get_IndicateList:
            state.indicateList=action.data;
            state.isIndicateListShow = true;
            return {...state};
        default:
            return state;
    }
}
