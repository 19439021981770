import React, {Component} from "react";
import {connect} from 'react-redux';

import * as action from "../../store/home/action";
import * as actionHeader from "../../store/shared/header/action";
import { debounce } from '../../utils/utils'
import { getCookie } from '../../utils/cookieUtils';

import bgUrl from '../../assets/images/home/bg.png';
import iconEyeUrl from '../../assets/images/home/icon-eye.png';
import iconHotUrl from '../../assets/images/home/icon-hot.png';
import iconListUrl from '../../assets/images/home/list.png';
import search_searchUrl from '../../assets/images/home/search_search.png';
import miniProgramUrl from '../../assets/images/home/mini_program.png';

import {entryList, panelList0, hotTopicList} from '../../constant/entry-list.js'
import envconfig from '../../envconfig/envconfig';
import style from '../../assets/css/home/home.module.scss';


// const customerImgArr = [
//     ["001", "002", "003", "004", "005"],
//     ["1", "2", "3", "4", "5", "6", "7"],
//     ["01", "02", "03", "04", "05"],
//     ["001", "002", "003", "004", "005"],
//     ["1", "2", "3", "4", "5", "6", "7"]
// ]
// const customerImgUrlArr = customerImgArr.map(row => {
//     return row.map(item => require("../../assets/images/home/user_" + item + ".png"))
// })

const userImgs = [
    ["1", "2", "3", "4", "5", "6", "7"],
    ["01", "02", "03", "04", "05"],
    ["001", "002", "003", "004", "005"],
]

const userImgUrlArr = userImgs.map(row => {
    return row.map(item => require("../../assets/images/home/new/user/user_" + item + ".png"))
})

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    handleContactModal(key, value) {
        this.props.handleIsAddUserAdviceSuccess('isAddUserAdviceSuccess', -1);
        this.props.handleClear();
        this.props.handleContactModal(key, value);
    }

    render() {
        let {state, stateHeader} = this.props;
        //banner轮播
        let {step, speed, bannerList, cloneBannerList, searchList, checkedSearch, dataCount} = this.props.state;
        let _style = {
            width: 100 * cloneBannerList.length + '%',
            left: -100 * step + '%',
            transition: `left ${speed}ms linear 0ms`
        };
        let singleWidth = 1 / cloneBannerList.length * 100 + '%';
        //典型用户轮播
        // let {stepCustomer, speedCustomer, cloneCustomerList} = this.props.state;
        // let _styleCustomer = {
        //     height: 100 * cloneCustomerList.length + '%',
        //     top: -100 * stepCustomer + '%',
        //     transition: `top ${speedCustomer}ms linear 0ms`
        // };
        // let singleHeightCustomer = 1 / cloneCustomerList.length * 100 + '%';
        return (
            <div className={'container'}>
                <div
                    className={style['banner']}
                    onMouseOver={() => (clearInterval(this.timer))}
                    onMouseOut={() => (this.timer = setInterval(this.props.scroll, this.props.state.interval))}
                >
                    <ul className={style['btn-list']}>
                        {
                            bannerList.map((item, index) => {
                                return (
                                    <span key={index} className={index + 1 === step ? style['current'] : ''}
                                          onClick={() => this.props.scrollTo(index + 1)}/>
                                )
                            })
                        }
                    </ul>
                    <div className={style['banner-list']} style={_style}>
                        {
                            cloneBannerList.map((item, index) => {
                                return (
                                    <a
                                        key={index} href={item.picUrlNew || state.publicizeUrl}
                                        target={item.picUrlNew ? '_self' : '_blank'}
                                        style={{
                                            width: singleWidth,
                                            background: 'url(' + item.picPathNew + ')' + 'center center no-repeat',
                                            backgroundSize: 'cover'
                                        }}
                                    >
                                        {/* <img src={item.picPatnhNew} /> */}
                                    </a>
                                )
                            })
                        }
                    </div>
                </div>
                <div className={'wrap'}>
                    <div className={'main ' + style['main']}>
                        <div className={style['search']}>
                            <div className={style['dropdown']}
                                 onClick={() => this.props.retValue('isPanelShow', !state.isPanelShow)}>
                                <div className={style['search-type']}>{checkedSearch.title}</div>
                                <ul className={`dropdown-menu ${style['dropdown-list']} ${state.isPanelShow ? 'show' : 'hide'}`}>
                                    {
                                        searchList.map((item, index) => {
                                            return (
                                                <li key={index}
                                                    className={`${checkedSearch.title == item.title ? style['current'] : ''}`}
                                                    onClick={() => this.handlePanel(item)}>{item.title}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className={style['keywrod']}>
                                <p className={style['placeholder']}
                                   style={{display: !state.isFocus && dataCount.index ? 'block' : 'none'}}>
                                    <span>{dataCount.index}</span>个指标，<span>{dataCount.value}</span>笔数值数据，<span>{dataCount.item}</span>条条目数据，<span>{dataCount.kind}</span>种、<span>{dataCount.book}</span>册统计资料，<span>{dataCount.news}</span>条资讯数据
                                </p>
                                <input
                                    className={style['search-input']} value={state.kd}
                                    onChange={(e) => this.props.retValue("kd", e.target.value)}
                                    onKeyDown={(e) => {
                                        this.handleEnterKey(e, state.kd)
                                    }}
                                    type="text"
                                    onFocus={() => this.props.retValue('isFocus', true)}
                                    onKeyUp={debounce((e) => this.getIndicateList(e),700)}
                                    onBlur={(e) => this.onBlur(e)}
                                />
                                <i onClick={() => this.toSearch()}></i>
                                <ul
                                    className={(state.isIndicateListShow && state.indicateList.length > 0 ? 'show' : 'hide') + ' dropdown-menu ' + style['dropdown-menu']}
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    {
                                        this.props.state.indicateList.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <div className={style['module-header']}>
                                                        <svg className={style["icons"]} aria-hidden="true">
                                                            <use xlinkHref={this.getIcons(item)}/>
                                                        </svg>
                                                        <span>{item.name}</span>
                                                    </div>
                                                    <ul className={style['module-body']}>
                                                        {
                                                            item.searchPointOutItems.map((el, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        <a href={this.getUrl(item.name, el)}
                                                                           title={el.indicate}>{el.indicate}</a>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <a
                                className={style['ai']}
                                onMouseEnter={() => this.props.retValue('showAiTip', true)}
                                onMouseLeave={() => this.props.retValue('showAiTip', false)}
                                // href={`./intelligentAnalysis?kd=${state.kd.trim() ? state.kd : 'GDP'}`}
                                href={`./intelligentAnalysis?kd=${state.kd.trim()}`}

                            >
                                <div className={style['ai-btn']}>
                                    <div className={style['welcome']}><span>欢迎试用</span></div>
                                    智能分析
                                </div>
                                {state.showAiTip && (
                                    <div className={style['tooltips']}>
                                        支持语句搜索，如可输入“北京近五年GDP”
                                    </div>
                                )}
                            </a>
                        </div>
                        <div className={style['entry-list']}>
                            <span className={style['entry-title']}>热门导航：</span>
                            <ul>
                                {
                                    entryList.map((item, index) => (
                                        <li key={index} className={style['entry-' + index]}>

                                            <a href={item.url} target={'_blank'}>
                                                <span className={style['entry-icon']}/>
                                                <span className={item.isNew ? style['icon-new'] :''}>{item.name}</span>
                                            </a>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className={'panel ' + style['panel'] + ' ' + style['panel-card-0']}>
                            {panelList0.map((item, index) => {
                                return (
                                    <div key={`card_${index}`} className={style['card-item']}>
                                        <span className={style['card-title']}>{item.title}</span>
                                        <ul className={style['list-wrap']}>
                                            {item.list.map(cItem => (
                                                <li key={cItem.url}>
                                                    <a href={cItem.url}>{cItem.name}</a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )
                            })}
                        </div>
                        <div className={'panel ' + style['panel'] + ' ' + style['panel-card-1']}>
                            {/*<div className={style['panel-header']}>*/}
                            {/*    <span className={style['title']}>热点专题</span>*/}
                            {/*</div>*/}
                            {/*<ul className={style['panel-hotopic']}>*/}
                            {/*    {hotTopicList.map((item, index) => (*/}
                            {/*        <li key={`hot_${index}`}*/}
                            {/*            className={style['panel-hot'] + ' ' + style[`panel-hot-${index + 1}`] + ' ' + (item.isNew ? style['icon-new'] : '')}>*/}
                            {/*            <a href={item.url} target="_blank"></a>*/}
                            {/*        </li>*/}
                            {/*    ))}*/}
                            {/*</ul>*/}

                            <span className={style['card-title']}>热点专题</span>
                            <ul className={style['panel-hotopic']}>
                                {hotTopicList.map((item, index) => (
                                    <li key={`hot_${index}`}
                                        className={style['panel-hot'] + ' ' + style[`panel-hot-${index + 1}`]}
                                    >
                                        <a href={item.url} target="_blank" className={item.isNew ? style['icon-new'] : ''}>{item.name}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className={'panel ' + style['panel'] + ' ' + style['panel-card-2']}>
                            <div className={style['card-item-1']}>
                                <span className={style['card-title']}>地域版</span>
                                <ul className={style['list-wrap']}>
                                    {state.areas.map(item => (
                                        <li key={item.DCode}>
                                            <a href={'./area/home?dcode=' + item.DCode}
                                               target={'_blank'}>{item.AreaName}</a>
                                        </li>
                                    ))}
                                </ul>
                                <span className={style['tips']}>注：不含港澳台</span>
                            </div>
                            <div className={style['card-item-2']}>
                                <span className={style['card-title']}>行业版</span>
                                <ul className={style['list-wrap']}>
                                    {stateHeader.industryList.map(item => (
                                        <li key={item.industryCode}>
                                            <a href={'./trade/home?zcode=' + item.industryCode} target={'_blank'}>
                                                {item.industryName}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className={'panel ' + style['panel'] + ' ' + style['panel-2']}>
                            <ul className={style['panel-body']}>
                                {
                                    this.props.state.themeList.map((item, index) => {
                                        if (index === 2) {
                                            return (
                                                <li key={index} className={style['panel-box-03']}>
                                                    <h5 className={style['header']}>
                                                        <span className={style['panel-title']}>{item.resume}</span>
                                                        <a href='./competition' target={'_blank'}></a>
                                                    </h5>
                                                    <img src={item.picUrl} alt='图片加载失败'/>
                                                    <a
                                                        title={item.title}
                                                        className={style['title']}
                                                        target="_blank"
                                                        href={`https://data.cnki.net/jingsai/view-pdf/${item.articleUrl}.pdf`}
                                                    >
                                                        {item.title ? (item.title.length < 20 ? item.title : item.title.substring(0, 20) + '...') : ''}
                                                    </a>
                                                    <p>{item.contents ? item.contents.length < 48 ? item.contents : (item.contents.substring(0, 48) + '...') : ''}</p>
                                                    <ul className={style['hot-list']}>
                                                        {
                                                            item.themeItemList.map((el, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        <img src={iconHotUrl}
                                                                             className={style['icon-hot']}/>
                                                                        <a
                                                                            className={style['hot-title']}
                                                                            target="_blank"
                                                                            href={`https://data.cnki.net/jingsai/view-pdf/${el.articleUrl}.pdf`}
                                                                            title={el.title}
                                                                        >
                                                                            {el.title.length < 20 ? el.title : el.title.substring(0, 20) + '...'}
                                                                        </a>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </li>
                                            )
                                        }
                                        return (
                                            <li key={index} className={style['panel-box-0' + (index + 1)]}
                                                style={{display: index === 3 ? 'none' : 'normal'}}>
                                                <h5 className={style['header']}>
                                                    <span className={style['panel-title']}>{item.resume}</span>
                                                    <a href={this.props.state.pageUrl[item.resume] + "/index"}
                                                       target={'_blank'}></a>
                                                </h5>
                                                <img src={item.picUrl} alt='图片加载失败'/>
                                                <a title={item.title} className={style['title']} target="_blank"
                                                   href={this.props.state.pageUrl[item.resume] + "/article?id=" + item.id}>
                                                    {item.title ? (item.title.length < 16 ? item.title : item.title.substring(0, 16) + '...') : ''}
                                                    <div className={style['log-count']}>
                                                        <img src={iconEyeUrl}/>
                                                        {item.logCount}
                                                    </div>
                                                </a>
                                                <p>
                                                    {item.contents ? item.contents.length < 48 ? item.contents : (item.contents.substring(0, 48) + '...') : ''}
                                                    <span
                                                        className={style['sp-time']}>{item.createTime ? item.createTime.substring(0, 10).replace(/-/g, ".") : ''}</span>
                                                </p>
                                                <ul className={style['hot-list']}>
                                                    {
                                                        item.themeItemList.map((el, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    <img src={iconHotUrl}
                                                                         className={style['icon-hot']}/>
                                                                    <a className={style['hot-title']} target="_blank"
                                                                       href={this.props.state.pageUrl[item.resume] + "/article?id=" + el.id}
                                                                       title={el.title}>
                                                                        {el.title.length < 16 ? el.title : el.title.substring(0, 16) + '...'}
                                                                    </a>
                                                                    <span
                                                                        className={style['sp-time']}>{el.createTime.substring(0, 10)}</span>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className={'panel ' + style['panel'] + ' ' + style['panel-3']}>
                            <div className={style['panel-header']}>
                                <span className={style['title']}>最新上线</span>
                            </div>
                            <ul className={style['panel-body']}>
                                {
                                    this.props.state.newOnline.map((item, index) => {
                                        return (
                                            <li key={index}
                                                className={style['panel-box'] + ' ' + style['panel-box-0' + (index + 1)]}>
                                                <h5 className={style['header']}>
                                                    <span className={style['panel-title']}>{item.name}</span>
                                                    {index === 1 ? (
                                                        <a
                                                            href={envconfig.rootUrl + "/statisticalInfo/index?ky="}
                                                            target={'_blank'}
                                                        ></a>
                                                    ) : null}
                                                </h5>
                                                <ul>
                                                    {
                                                        item.lastUpdateItems.map((el, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    <span className={style['list-dot']}></span>
                                                                    <a title={el.name_zh} target={'_blank'}
                                                                       onClick={() => this.toResult(item, el)}>{el.name_zh}</a>
                                                                    <span className={style['sp-time']}>{el.years}</span>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className={'panel ' + style['panel'] + ' ' + style['panel-card-3']}>
                            <div className={style['panel-header']}>
                                <span className={style['title']}>典型用户</span>
                            </div>
                            <div className={style['panel-body']}>

                                {userImgUrlArr.map((item, index) => {
                                    return (
                                        <ul key={`user_${index}`}>
                                            {item.map((cItem, cIndex) => {
                                                return (
                                                    <li key={`user_item_${index}_${cIndex}`}>
                                                        <img src={cItem.default} alt=""/>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    )
                                })}

                            </div>
                        </div>
                    </div>
                    {/*<div className={style['bg-0']}>*/}
                    {/*    <img src={bgUrl}/>*/}
                    {/*</div>*/}
                    <div className={style['bg-01']}/>
                    <div className={style['bg-02']}/>
                    <div className={style['bg-03']}/>
                </div>
                <div className={'wrap ' + style['bottom']}>
                    <div className={'main'}>
                        <div className={style['left']}>
                            <div className={style['title']}>其他服务</div>
                            <nav>
                                {/* <a href={state.userVideoUrl} target={'_blank'}>操作视频下载</a>
                                <a href={state.userDocUrl} target={'_blank'}>用户使用手册</a> */}
                                <a className={'link'} onClick={() => this.handleContactModal('isShow', true)}>联系反馈</a>
                                <a>cnki_number@cnki.net</a>
                            </nav>
                        </div>
                        <div className={style['right']}>
                            <div className={style['title']}>小程序</div>
                            <img src={miniProgramUrl}/>
                            <img src={search_searchUrl}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentWillMount() {
        // this.props.getEntryList();
    }

    onBlur(e) {
        if (!e.target.value) {
            this.props.retValue('isFocus', false)
        }
        this.props.handleIndicateList('isIndicateListShow', false)
    }

    handleEnterKey(event) {
        let e = event || window.event
        if (e && e.keyCode === 13) {
            this.toSearch()
        }
    }

    toSearch() {
        const {checkedSearch, kd} = this.props.state
        // window.location.href = `${checkedSearch.url}${kd.trim() ? kd : 'GDP'}`
        window.location.href = `${checkedSearch.url}${kd.trim()}`

    }

    handlePanel(item) {
        this.props.retValue('checkedSearch', {...item})
        this.props.retValue('isPanelShow', false)
    }

    toResult(item, el) {
        if (item.name === "统计资讯") {
            window.open(this.props.state.pageUrl["统计资讯详情"] + el.code)
        } else if (this.props.state.pageUrl[item.name]) {
            window.open(this.props.state.pageUrl[item.name] + el.code)
        } else if (item.name === "进度数据发布更新") {
            this.props.getDataPublish(el.code)
            return false;
        }
    }

    getIndicateList(e) {
        if (e && e.keyCode === 13) {
            this.toSearch()
        } else {
            const value = e.target.value;
            if (value?.trim().length <= 0) {
                this.props.handleIndicateList('isIndicateListShow', false)
                return
            }
            let data = {
                kd: e.target.value.trim()
            }
            if (e.target.value.trim().length > 0) {
                this.props.getIndicateList(data)
            }
        }
    }

    getIcons(item) {
        switch (item.name) {
            case "统计指标":
                return "#icon-folder-chart-line"
            case "条目题名":
                return "#icon-t-box-line"
            case "统计资料":
                return "#icon-stack-line"
            case "统计资讯":
                return "#icon-newspaper-line"
            default:
                return ""
        }
    }

    getUrl(val, el) {
        switch (val) {
            case "统计指标":
                return "./valueSearch/index?ky=" + encodeURIComponent(el.indicate)
            case "条目题名":
                return "./itemSearch/index?ky=" + encodeURIComponent(el.indicate)
            case "统计资料":
                return "./statisticalData/index?ky=" + encodeURIComponent(el.indicate)
            case "统计资讯":
                return "./statisticalInfo/index?ky=" + encodeURIComponent(el.indicate)
            default:
                return ""
        }
    }

    componentDidMount() {
        this.props.getBannerList();
        this.props.getNewOnline();
        this.props.getThemesList();
        const isLogin = !!getCookie('LID')
        if (isLogin) {
            this.props.getDataCount();
        }

        this.timer = setInterval(this.props.scroll, this.props.state.interval);
        // setInterval(this.props.scrollCustomer, this.props.state.intervalCustomer);
    }

    componentWillUpdate(nextProps, nextState) {
        let {step, stepCustomer, bannerList, customerList} = this.props.state;
        //边界判断：如果最新修改的STEP索引大于最大索引（说明此时已经是末尾了，不能在向后走了），我们让其立即回到（无动画）索引为1的位置
        if (step > bannerList.length) {
            this.props.initScroll();
        }
        // //边界判断：如果最新修改的STEP索引大于最大索引（说明此时已经是末尾了，不能在向后走了），我们让其立即回到（无动画）索引为1的位置
        // if (stepCustomer > customerList.length) {
        //     this.props.initScrollCustomer();
        // }
    }

    componentDidUpdate(nextProps) {
        //只有是从克隆的第一张立即切换到真实第一张后，我们才做如下处理：让其从当前第一张运动到第二张即可
        let {step, stepCustomer, speed, speedCustomer} = this.props.state;
        if (step === 0 && speed === 0) {
            //为啥要设置定时器延迟：CSS3的transition有一个问题（主栈执行的时候，短时间内遇到两次设置TRANSITION-DRRATION的代码，以最后一次设置的为主）
            let delayTimer = setTimeout(() => {
                clearTimeout(delayTimer);
                this.props.reScroll(step, speed);
            }, 0);
        }
        // if (stepCustomer === 0 && speedCustomer === 0) {
        //     //为啥要设置定时器延迟：CSS3的transition有一个问题（主栈执行的时候，短时间内遇到两次设置TRANSITION-DRRATION的代码，以最后一次设置的为主）
        //     let delayTimer = setTimeout(() => {
        //         clearTimeout(delayTimer);
        //         this.props.reScrollCustomer(stepCustomer, speedCustomer);
        //     }, 0);
        // }
    }

}

//需要渲染什么数据
function mapStateToProps(state) {
    return {
        state: state.home,
        stateHeader: state.header
    }
}

//需要触发什么行为
function mapDispatchToProps(dispatch) {
    return {
        getVersion: () => dispatch(action.getVersion()),
        // getEntryList: () => dispatch(action.getEntryList()),
        getBannerList: () => dispatch(action.getBannerPicList()),
        scroll: () => dispatch(action.scroll()),
        initScroll: () => dispatch(action.initScroll()),
        retValue: (key, value) => dispatch(action.retValue(key, value)),
        reScroll: (step, speed) => dispatch(action.reScroll(step, speed)),
        scrollTo: (step) => dispatch(action.scrollTo(step)),
        scrollCustomer: () => dispatch(action.scrollCustomer()),
        initScrollCustomer: () => dispatch(action.initScrollCustomer()),
        reScrollCustomer: (step, speed) => dispatch(action.reScrollCustomer(step, speed)),
        getDataPublish: (data) => dispatch(action.getDataPublish(data)),
        getNewOnline: () => dispatch(action.getNewOnline()),
        getThemesList: () => dispatch(action.getThemesList()),
        addUserAdvice: () => dispatch(action.addUserAdvice()),
        getDataCount: () => dispatch(action.getDataCount()),
        getLoginStatus: () => dispatch(action.getLoginStatus()),
        handleContactModal: (key, value) => dispatch(actionHeader.handleKeyValue(key, value)),
        handleClear: () => dispatch(actionHeader.handleClear()),
        handleIsAddUserAdviceSuccess: (key, value) => dispatch(actionHeader.handleKeyValue(key, value)),
        handleIndicateList: (key, value) => dispatch(action.retValue(key, value)),
        getIndicateList: (data) => dispatch(action.getIndicateList(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
