import React, {Component} from "react";

import FooterOverseas from './footerOverseas';
import cnnicUrl from '../../assets/images/shared/cnnic.jpg';

import style from '../../assets/css/shared/footer.module.scss';
import envconfig from "../../envconfig/envconfig";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const version = envconfig.version
        if (version !== 'overseas') {
            return (
                <div className={style['footer']} id={"footer"}>
                    <div className={'main'}>
                        <nav>
                            <span>京 ICP 证 040431 号</span>
                            <a href={'//piccache.cnki.net/index/images/gb/271.jpg'} target={'_blank'}>网络出版服务许可证 (总)网出证(京)字第 271 号</a>
                            <a href={'//piccache.cnki.net/index/images/gb/170001.jpg'} target={'_blank'}>出版物经营许可证</a>
                            {/* <a href={'http://www.hd315.gov.cn/beian/view.asp?bianhao=010202005012100021'} target={'_blank'}>经营性网站备案信息</a> */}
                            <span>京公网安备 11010802020460 号</span>
                        </nav>
                        <div className={style['bottom']}>
                            <span>《中国学术期刊（光盘版）》电子杂志社有限公司</span>
                            <span>KDN 平台基础技术由 KBASE 11.0 提供</span>
                        </div>
                        <div className={style['right']}>
                            <a href={'https://ss.knet.cn/verifyseal.dll?sn=e13111111010043364isll000000&a=1&pa=0.08881110103584755'} target={'_blank'}>
                                <img src={cnnicUrl} />
                            </a>
                        </div>
                    </div>
                </div>
            )
        }

        return <FooterOverseas />

    }
}


export default Footer
