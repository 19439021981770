
//获取版本
export const Get_Version = 'Get_Version';
//获取快捷入口
export const Get_EntryList = 'Get_EntryList';
//显示/隐藏Modal
export const SHOW_MODAL = 'SHOW_MODAL';
//获取banner列表
export const GET_BANNERLIST = 'GET_BANNERLIST';
//banner轮播
export const SCROLL = 'SCROLL';
//banner轮播
export const RE_SCROLL = 'RE_SCROLL';
//banner轮播
export const SCROLL_INIT = 'SCROLL_INIT';
//banner轮播
export const SCROLL_TO = 'SCROLL_TO';

//典型用户轮播
export const SCROLL_Customer = 'SCROLL_Customer';
//典型用户轮播
export const SCROLL_INIT_Customer = 'SCROLL_INIT_Customer';
//典型用户轮播
export const RE_SCROLL_Customer = 'RE_SCROLL_Customer';

//获取最新课题研究,用户报告,统计播报
export const Get_ThemesList = 'Get_ThemesList';
//获取最新上线
export const Get_NewOnline = 'Get_NewOnline';
//用户反馈
export const Add_UserAdvice = 'Add_UserAdvice';
//获取检索值
export const Get_DataCount = 'Get_DataCount';
//判断登录状态
export const Get_LoginStatus = 'Get_LoginStatus';
//获取搜索框提示词
export const Get_IndicateList = 'Get_IndicateList';
