//显示/隐藏地域版、行业版下拉框
export const handleKeyValue = 'Handle_KeyValue';
//获取版本
export const Get_Version = 'Get_Version';
//获取登录模式
export const GetLoginMode = 'GetLoginMode';
//获取行业版列表
export const Get_IndustryList = 'Get_IndustryList';
//获取搜索框提示词
export const Get_IndicateList = 'Get_IndicateList';
//显示/隐藏modal
export const ShowModal = 'ShowModal';
//表单绑定
export const Value_Change = 'Value_Change';
//提交数据
export const Submit = 'Submit';
//清空
export const Clear = 'Clear';
//获取检索值
export const Get_DataCount = 'Get_DataCount';
//判断登录状态
export const Get_LoginStatus = 'Get_LoginStatus';
