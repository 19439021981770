import envconfig from "../envconfig/envconfig";

const env = process.env.API_ENV || 'production';

const entryList =  [
    {
        "name": "中国指数",
        "url": "./businessIndex/index",
    },
    {
        "name": "进度数据发布",
        "url": "./datapublish/month",
    },
    {
        "name": "数值型数据建库系统",
        "url": "https://szjk.cnki.net/#/login"
    },
    // {
    //     "name": "中国指数",
    //     "url": "./businessIndex/index"
    // },
    // {
    //     "name": "进度数据发布",
    //     "url": "./datapublish/month"
    // },
    // {
    //     "name": "数值型数据建库系统",
    //     "url": "https://szjk.cnki.net/#/login"
    // },
    // {
    //     "name": "数据定制",
    //     "url": "./dataCustom/index"
    // }
]

const panelList0 = [
    {
        title: '统计资料',
        list: [
            {
                name: '统计年鉴',
                url: './yearBook?type=type&code=A'
            },
            {
                name: '分析报告',
                url: './yearBook?type=type&code=B'
            },
            {
                name: '资料汇编',
                url: './yearBook?type=type&code=C'
            },
            {
                name: '调查资料',
                url: './yearBook?type=type&code=D'
            },
            {
                name: '普查资料',
                url: './yearBook?type=type&code=E'
            },
            {
                name: '统计摘要',
                url: './yearBook?type=type&code=F'
            },
            {
                name: '统计公报',
                url: './censusBulletin'
            },
        ]
    },
    {
        title: '数据分析',
        list: [
            {
                name: '年度数据分析',
                url: './yearData'
            },
            {
                name: '进度数据分析',
                url: './seasonOrMonth'
            },
            {
                name: '国际数据分析',
                url: './internationalData'
            },
            {
                name: '专题数据分析',
                url: './themeAnalysis/analysis',
                notOverseas: true
            }
        ]
    },
    {
        title: '决策支持',
        list: [
            {
                name: '相关分析',
                url: './decision/methods/regressionAnalysis?type=year&id=&dcode=&zcode='
            },
            {
                name: '统计预测',
                url: './decision/methods/timeSeriesTrendPredict?type=year&id=&dcode=&zcode='
            },
            {
                name: '科学评价',
                url: './decision/methods/ahp?type=year&id=&dcode=&zcode='
            },
            {
                name: '决策模型',
                url: './decision/methods/zoneDecision?type=year&dcode=&zcode='
            },
            {
                name: '数据智能分析平台',
                url: envconfig.dsUrl,
                notOverseas: true
            }
        ]
    },
]
const hotTopicListDev = [
    {
        name: '经济运行监测分析系统',
        url: 'http://65.cnki.net/dpi/eom/#/macroeconomy',
    },
    {
        name: '中国人口普查数据库',
        url: 'https://data.cnki.net/npcd'
    },
    {
        name: '中国城市统计数据库 (进度数据版)',
        // url: 'http://citydata.cnki.net/#/data-easysearchReplace?dimensionId=e039bbba4f314226849afd6cc29ffb19&type=1'
        url: 'https://citydata.cnki.net/#/index'
    },
    {
        name: '科技专题数据库',
        url: 'http://65.cnki.net/cnki/net/#/data-easysearchReplace?dimensionId=3591c6551573483286715063ded94d3b&type=1',
    },
    {
        name: '能源专题数据库',
        url: 'http://65.cnki.net/cnki/net/#/data-easysearchReplace?dimensionId=aa1f1a8297a94c1fa454bb12c337c7fe&type=1',
    },
    {
        name: '卫生健康专题数据库',
        url: 'http://65.cnki.net/cnki/net/#/data-easysearchReplace?dimensionId=9eb3a887aa9c444a9c63b093f896e16b&type=1',
    },
    {
        name: '乡村振兴专题数据库',
        url: 'http://65.cnki.net/cnki/net/#/data-easysearchReplace?dimensionId=59dd50b29deb43e389e613268fc637a3&type=1',
    },
    {
        name: '数字经济专题数据库',
        url: 'http://65.cnki.net/cnki/net/#/data-easysearchReplace?dimensionId=7a53e1fd167d41b49cc4c276370f80cb&type=1',
    },
]
const hotTopicListProd = [
    {
        name: '经济运行监测分析系统',
        // url: 'https://data.cnki.net/eom',
        url: 'http://szjk.cnki.net/dpiv2/eom/#/macroeconomy/',
        isNew: false
    },
    {
        name: '中国人口普查数据库',
        url: 'https://data.cnki.net/npcd'
    },
    {
        name: '中国城市统计数据库 (进度数据版)',
        // url: 'https://data.cnki.net/citydata'
        // url: 'http://citydata.cnki.net/#/data-easysearchReplace?dimensionId=e039bbba4f314226849afd6cc29ffb19&type=1'
        url: 'https://citydata.cnki.net/#/index'
    },
    {
        name: '科技专题数据库',
        // url: 'https://data.cnki.net/kjdata',
        url: 'http://szjk.cnki.net/dpiv2/search/#/data-easysearch?dimensionId=3591c6551573483286715063ded94d3b&type=1',
    },
    {
        name: '能源专题数据库',
        // url: 'https://data.cnki.net/nydata',
        url: 'http://szjk.cnki.net/dpiv2/search/#/data-easysearch?dimensionId=aa1f1a8297a94c1fa454bb12c337c7fe&type=1',
    },
    {
        name: '卫生健康专题数据库',
        // url: 'https://data.cnki.net/wsjkdata',
        url: 'http://szjk.cnki.net/dpiv2/search/#/data-easysearch?dimensionId=9eb3a887aa9c444a9c63b093f896e16b&type=1',
    },
    {
        name: '乡村振兴专题数据库',
        url: 'http://szjk.cnki.net/dpiv2/search/#/data-easysearch?dimensionId=59dd50b29deb43e389e613268fc637a3&type=1',
    },
    {
        name: '数字经济专题数据库',
        url: 'http://szjk.cnki.net/dpiv2/search/#/data-easysearch?dimensionId=7a53e1fd167d41b49cc4c276370f80cb&type=1',
    },
]

const hotTopicList = env === 'production' ? hotTopicListProd : hotTopicListDev;

export { entryList, panelList0, hotTopicList }
