import * as type from './action-type';
import envconfig from "../../../envconfig/envconfig"
import { set } from '../../../utils/localStorageUtils'
let _state = {
    version: '',//版本，center:中心版,overseas:海外版,mirror:镜像版
    loginStatus: 0,//登录状态
    userName:'',//用户名
    loginMode: 0,//登录模式，0：统一登录，1：本地登录

    isDataCustom:false,
    areas:[
        {
            AreaName:'安徽',
            PYName:'anhui',
            DCode: 'D12'
        },
        {
            AreaName:'北京',
            PYName:'beijing',
            DCode: 'D01'
        },
        {
            AreaName:'重庆',
            PYName:'chongqing',
            DCode: 'D22'
        },
        {
            AreaName:'福建',
            PYName:'fujian',
            DCode: 'D13'
        },
        {
            AreaName:'广东',
            PYName:'guangdong',
            DCode: 'D19'
        },
        {
            AreaName:'甘肃',
            PYName:'gansu',
            DCode: 'D28'
        },
        {
            AreaName:'广西',
            PYName:'guangxi',
            DCode: 'D20'
        },
        {
            AreaName:'贵州',
            PYName:'guizhou',
            DCode: 'D24'
        },
        {
            AreaName:'河北',
            PYName:'hebei',
            DCode: 'D03'
        },
        {
            AreaName:'黑龙江',
            PYName:'heilongjiang',
            DCode: 'D08'
        },
        {
            AreaName:'河南',
            PYName:'henan',
            DCode: 'D16'
        },
        {
            AreaName:'湖南',
            PYName:'hunan',
            DCode: 'D18'
        },
        {
            AreaName:'海南',
            PYName:'hainan',
            DCode: 'D21'
        },
        {
            AreaName:'吉林',
            PYName:'jilin',
            DCode: 'D07'
        },
        {
            AreaName:'江苏',
            PYName:'jiangsu',
            DCode: 'D10'
        },
        {
            AreaName:'湖北',
            PYName:'hubei',
            DCode: 'D17'
        },
        {
            AreaName:'江西',
            PYName:'jiangxi',
            DCode: 'D14'
        },
        {
            AreaName:'辽宁',
            PYName:'liaoning',
            DCode: 'D06'
        },
        {
            AreaName:'内蒙古',
            PYName:'neimenggu',
            DCode: 'D05'
        },
        {
            AreaName:'宁夏',
            PYName:'ningxia',
            DCode: 'D30'
        },
        {
            AreaName:'青海',
            PYName:'qinghai',
            DCode: 'D29'
        },
        {
            AreaName:'四川',
            PYName:'sichuan',
            DCode: 'D23'
        },
        {
            AreaName:'山东',
            PYName:'shandong',
            DCode: 'D15'
        },
        {
            AreaName:'山西',
            PYName:'shanxi',
            DCode: 'D04'
        },
        {
            AreaName:'陕西',
            PYName:'shanxi',
            DCode: 'D27'
        },
        {
            AreaName:'上海',
            PYName:'shanghai',
            DCode: 'D09'
        },
        {
            AreaName:'天津',
            PYName:'tianjin',
            DCode: 'D02'
        },
        {
            AreaName:'西藏',
            PYName:'xizang',
            DCode: 'D26'
        },
        {
            AreaName:'新疆',
            PYName:'xinjiang',
            DCode: 'D31'
        },
        {
            AreaName:'云南',
            PYName:'yunnan',
            DCode: 'D25'
        },
        {
            AreaName:'浙江',
            PYName:'zhejiang',
            DCode: 'D11'
        }
    ],
    areaGroups:[
        {
            start: 'A',
            end: 'G'
        },
        {
            start: 'H',
            end: 'J'
        },
        {
            start: 'L',
            end: 'S'
        },
        {
            start: 'T',
            end: 'Z'
        }
    ],
    trades:[],
    isAreaMenuShow:false,
    isTradeMenuShow:false,
    isIndicateListShow:false,
    nav:[
        {
            name: '首页',
            url: './home'
        },
        {
            name: '统计资料',
            url: './yearBook',
            sub: [
                {
                    name:'统计年鉴',
                    url: './yearBook?type=type&code=A'
                },
                {
                    name:'分析报告',
                    url: './yearBook?type=type&code=B'
                },
                {
                    name:'资料汇编',
                    url: './yearBook?type=type&code=C'
                },
                {
                    name:'调查资料',
                    url: './yearBook?type=type&code=D'
                },

                {
                    name:'普查资料',
                    url: './yearBook?type=type&code=E'
                },
                {
                    name: '统计摘要',
                    url: './yearBook?type=type&code=F'
                },
                {
                    name: '统计公报',
                    url: './censusBulletin'
                }
            ],
        },
        {
            name: '数据分析',
            url: './yearData',
            sub:[
                {
                    name: '年度数据分析',
                    url: './yearData'
                },
                {
                    name: '进度数据分析',
                    url: './seasonOrMonth'
                },
                {
                    name: '国际数据分析',
                    url: './internationalData'
                },
                {
                    name: '专题数据分析',
                    url: './themeAnalysis/analysis',
                    notOverseas: true
                }
            ]
        },
        {
            name: '决策支持',
            url: './decision/methods/regressionAnalysis?type=year&id=&dcode=&zcode=',
            sub: [
                {
                    name: '相关分析',
                    url: './decision/methods/regressionAnalysis?type=year&id=&dcode=&zcode='
                },
                {
                    name: '统计预测',
                    url: './decision/methods/timeSeriesTrendPredict?type=year&id=&dcode=&zcode='
                },
                {
                    name: '科学评价',
                    url: './decision/methods/ahp?type=year&id=&dcode=&zcode='
                },
                {
                    name: '决策模型',
                    url: './decision/methods/zoneDecision?type=year&dcode=&zcode='
                },
                {
                    name: '数据智能分析平台',
                    url: envconfig.dsUrl,
                    notOverseas: true
                }
            ]
        },
        {
            name: '我的统计数据',
            url: './myData/myDataFavoriate?id=f1'
        }
    ],
    current: 0,
    industryList:[],
    indicateList:[],

    isContactModalShow: false,
    contact:'',
    advice:'',
    //提交状态，0：未提交，1：提交成功，2：提交失败
    isAddUserAdviceSuccess: -1,
    kd:'',
    timeOut:null,

    searchList:[
        {
            title:'统计指标',
            url:'./valueSearch/index?ky='
        },
        {
            title:"统计资料",
            url:'./statisticalData/index?ky='
        },
        {
            title:"条目题名",
            url:'./itemSearch/index?ky='
        },

        {
            title:'统计资讯',
            url:'./statisticalInfo/index?ky='
        }
    ],//检索下拉框
    checkedSearch:{
        title:"统计指标",
        url:'./valueSearch/index?ky='
    },
    dataCount:{},
    showAiTip: false, // 智能分析提示框
    isPanelShow:false,
    isFocus:false,
}

export const header=(state=_state,action)=> {
    switch (action.type) {
        case type.Get_Version:
            state.version = action.version;
            return {...state}
        case type.GetLoginMode:
            state.loginMode = action.loginMode;
            return {...state}
        case type.Get_IndustryList:
            state.industryList=action.data;
            return {...state}
        case type.Get_IndicateList:
            state.indicateList=action.data;
            state.isIndicateListShow = true;
            return {...state};
        case type.handleKeyValue:
            if (action.key === 'current' && action.value !== state.current) {
                set('current', action.value, 1)
            }
            state[[action.key]]=action.value;
            return {...state};
        case type.Value_Change:
            state[[action.key]]=action.value;
            return {...state};
        case type.Submit:
            state.isAddUserAdviceSuccess=action.isAddUserAdviceSuccess
            state.userName='';
            state.contact='';
            state.advice='';
            return {...state};
        case type.Clear:
            state.userName='';
            state.contact='';
            state.advice='';
            state.isAddUserAdviceSuccess=-1
            return {...state};
        case type.Get_DataCount:
            state.dataCount = action.data;
            return {...state}
        default:
            return state;
    }
}
