import * as type from "./action-type";
import API from "../../../api/api";

export const handleKeyValue = (key,value) => ({
    type:type.handleKeyValue,
    key: key,
    value:value
})

// 获取Version
export const getVersion = () => {
    // 返回函数，异步dispatch
    return async dispatch => {
        try{
            let result = await API.getVersion();
            dispatch({
                type: type.Get_Version,
                version: result
            })
        }
        catch(err){
            console.error(err);
        }
    }
}

// 获取登录模式
export const getLoginMode = () => {
    // 返回函数，异步dispatch
    return async dispatch => {
        try{
            let url = '/Auth/GetLoginMode';
            let result = await API.post(url, {});
            dispatch({
                type: type.GetLoginMode,
                loginMode: result.data
            })
        }
        catch(err){
            console.error(err);
        }
    }
}

// 获取行业版列表
export const getIndustryList = () => {
    // 返回函数，异步dispatch
    return async dispatch => {
        try{
            let result = await API.getIndustryList();
            dispatch({
                type: type.Get_IndustryList,
                data: result
            })
        }
        catch(err){
            console.error(err);
        }
    }
}

// 获取检索提示词
export const getIndicateList = (data) => {
    // 返回函数，异步dispatch
    return async dispatch => {
        try{
            if (data.kd === undefined || data.kd.trim() === '') {
                return false;
            }
            else{
                let url = '/Home/GetIndicateList';
                let result = await API.post(url,data);
                dispatch({
                    type: type.Get_IndicateList,
                    data: result.data
                })
            }
        }
        catch(err){
            console.error(err);
        }
    }
}

export const handleValueChange = (e,key) => ({
    type:type.Value_Change,
    key: key,
    value:e.target.value
})
export const handleClear = () => ({
    type:type.Clear
})
export const submit = (e,state) => {
    e.stopPropagation();
    let params={
        params:{
            nickname: state.userName,
            contact: state.contact,
            advice: state.advice
        }
    }
    let isAddUserAdviceSuccess = 0;

    // 返回函数，异步dispatch
    return async dispatch => {
        try{
            let result = await API.addUserAdvice(params);
            isAddUserAdviceSuccess = result === true ? 1 : 2;
            dispatch({
                type:type.Submit,
                isAddUserAdviceSuccess
            })
        }
        catch(err){
            console.error(err);
        }
    }
}

