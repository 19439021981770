import React, {Component} from "react";
import {connect} from 'react-redux';

import * as action from "../../store/home/action";
import * as actionHeader from "../../store/shared/header/action";

import bgUrl from '../../assets/images/home/bg.png';
import iconEyeUrl from '../../assets/images/home/icon-eye.png';
import iconHotUrl from '../../assets/images/home/icon-hot.png';
import iconListUrl from '../../assets/images/home/list.png';
import search_searchUrl from '../../assets/images/home/search_search.png';
import miniProgramUrl from '../../assets/images/home/mini_program.png';
import entryList from '../../constant/entry-list-mirror.js'

import style from '../../assets/css/home/homeMirror.module.scss';

const customerImgArr = [
    ["001", "002", "003", "004", "005"],
    ["1", "2", "3", "4", "5", "6", "7"],
    ["01", "02", "03", "04", "05"],
    ["001", "002", "003", "004", "005"],
    ["1", "2", "3", "4", "5", "6", "7"]
]
const customerImgUrlArr = customerImgArr.map(row => {
    return row.map(item => require("../../assets/images/home/user_" + item + ".png"))
})

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    handleContactModal(key, value) {
        this.props.handleIsAddUserAdviceSuccess('isAddUserAdviceSuccess', -1);
        this.props.handleClear();
        this.props.handleContactModal(key, value);
    }

    render() {
        let state = this.props.state;
        //banner轮播
        let {step, speed, bannerList, cloneBannerList} = this.props.state;
        let _style = {
            width: 100 * cloneBannerList.length + '%',
            left: -100 * step + '%',
            transition: `left ${speed}ms linear 0ms`
        };
        let singleWidth = 1 / cloneBannerList.length * 100 + '%';
        //典型用户轮播
        let {stepCustomer, speedCustomer, cloneCustomerList} = this.props.state;
        let _styleCustomer = {
            height: 100 * cloneCustomerList.length + '%',
            top: -100 * stepCustomer + '%',
            transition: `top ${speedCustomer}ms linear 0ms`
        };
        let singleHeightCustomer = 1 / cloneCustomerList.length * 100 + '%';
        return (
            <div className={'container'} onClick={() => this.props.handleIndicateList('isIndicateListShow', false)}>
                <div
                    className={style['banner']}
                    onMouseOver={() => (clearInterval(this.timer))}
                    onMouseOut={() => (this.timer = setInterval(this.props.scroll, this.props.state.interval))}
                >
                    <ul className={style['btn-list']}>
                        {
                            bannerList.map((item, index) => {
                                return (
                                    <span key={index} className={index + 1 === step ? style['current'] : ''} onClick={() => this.props.scrollTo(index + 1)} />
                                )
                            })
                        }
                    </ul>
                    <div className={style['banner-list']} style={_style}>
                        {
                            cloneBannerList.map((item, index) => {
                                return (
                                    <a key={index} href={item.picUrlNew} style={{
                                        width: singleWidth,
                                        background: 'url(' + item.picPathNew + ')' + 'center center no-repeat'
                                    }}>
                                        {/* <img src={item.picPathNew} /> */}
                                    </a>
                                )
                            })
                        }
                    </div>
                </div>
                <div className={'wrap'}>
                    <div className={'main ' + style['main']}>
                        <ul className={style['panel'] + ' panel ' + style['entry-list'] + ' ' + style['entry-list-mirror']}>

                            {

                                entryList.map((item, index) => {
                                    if (index < 4) {
                                        return (
                                            <li key={index} className={style['entry-' + index]}>
                                                <a href={item.url} target={'_blank'}>
                                                    <span className={style['entry-icon']} />
                                                    <span className={style['entry-num']}>{`0${index + 1}.`}</span>
                                                    <span>{item.name}</span>
                                                </a>
                                            </li>
                                        )
                                    }
                                    return null;
                                })
                            }
                        </ul>
                        {/* <div className={'panel ' + style['panel'] + ' ' + style['panel-3']}>
                            <div className={style['panel-header']}>
                                热点专题
                                <span className={style['icon-title'] + ' ' + style['icon-title-01']}></span>
                            </div>
                            <ul className={style['panel-hotopic']}>
                                <li className={style['panel-hot'] + ' ' + style['panel-hot-1']}>
                                    <a href="https://data.cnki.net/npcd" target="_blank"> 中国人口普查数据库</a>
                                </li>
                                <li
                                    className={style['panel-hot'] + ' ' + style['panel-hot-2']}>
                                    <a href="https://citydata.cnki.net/#/index" target="_blank"> 中国城市统计数据库(进度数据版)</a>
                                </li>
                            </ul>
                        </div> */}
                        <div className={'panel ' + style['panel'] + ' ' + style['panel-2-mirror']}>
                            <ul className={style['panel-body']}>
                                {
                                    this.props.state.themeListMirror.map((item, itemIndex) => {
                                        return (
                                            <li key={itemIndex} className={style['panel-box-0' + (itemIndex + 1)]}>
                                                <img className={style['img-mirror-' + itemIndex]}
                                                     src={item.picPath.default}/>
                                                <h5 className={style['header']}>{item.resume}</h5>
                                                <p title={item.contents}>{item.contents.length < 46 ? item.contents : (item.contents.substring(0, 46) + '...')}</p>
                                                <ul className={style['theme-entries']}>
                                                    {
                                                        item.themeEntryList.map((row, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    <ul>
                                                                        {
                                                                            row.map((item, index) => {
                                                                                return (
                                                                                    <li key={index}>
                                                                                        <a className={style['hot-title']}
                                                                                           href={item.url}
                                                                                           title={item.name}>
                                                                                            {item.name.length < 8 ? item.name : (item.name.substring(0, 8) + '...')}
                                                                                        </a>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className={'panel ' + style['panel'] + ' ' + style['panel-3']}>
                            <div className={style['panel-header']}>
                                最新上线
                                <span className={style['icon-title'] + ' ' + style['icon-title-01']}></span>
                            </div>
                            <ul className={style['panel-body']}>
                                {
                                    this.props.state.newOnline.map((item, index) => {
                                        return (
                                            <li key={index}
                                                className={style['panel-box'] + ' ' + style['panel-box-0' + (index + 1)]}>
                                                <h5>
                                                    <span>0{index + 1}</span>
                                                    {item.name}
                                                </h5>
                                                <ul>
                                                    {
                                                        item.lastUpdateItems.map((el, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    <span className={style['list-dot']}></span>
                                                                    <a title={el.name_zh} target={'_blank'}
                                                                       onClick={() => this.toResult(item, el)}>{el.name_zh}</a>
                                                                    <span className={style['sp-time']}>{el.years}</span>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className={'panel ' + style['panel'] + ' ' + style['panel-4']}>
                            <div className={style['panel-header']}>
                                典型用户
                                <span className={style['icon-title'] + ' ' + style['icon-title-02']}></span>
                            </div>
                            <div className={style['panel-body']}>
                                <ul className={style['customer']} style={_styleCustomer}>
                                    {
                                        cloneCustomerList.map((item, index) => {
                                            return (
                                                <li key={index} className={style['row' + {index}]}
                                                    style={{height: singleHeightCustomer}}>
                                                    {
                                                        item.map((item, subIndex) => {
                                                            return (
                                                                <a key={subIndex} className={style['col-' + {subIndex}]}
                                                                   style={{height: singleHeightCustomer}}>
                                                                    <img
                                                                        src={customerImgUrlArr[index][subIndex].default}/>
                                                                </a>
                                                            )
                                                        })
                                                    }
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={style['bg-0']}>
                        <img src={bgUrl}/>
                    </div>
                    <div className={style['bg-1']}>
                    </div>
                </div>
                <div className={'wrap ' + style['bottom']}>
                    <div className={'main'}>
                        <div className={style['left']}>
                            <div className={style['title']}>其他服务</div>
                            <nav>
                                <a href={state.userVideoUrl} target={'_blank'}>操作视频下载</a>
                                <a href={state.userDocUrl} target={'_blank'}>用户使用手册</a>
                                <a>cnki_number@cnki.net</a>
                            </nav>
                        </div>
                        <div className={style['right']}>
                            <div className={style['title']}>小程序</div>
                            <img src={miniProgramUrl}/>
                            <img src={search_searchUrl}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentWillMount() {
        // this.props.getEntryList();
    }

    toResult(item, el) {
        if (item.name === "统计资讯") {
            window.open(this.props.state.pageUrl["统计资讯详情"] + el.code)
        }
        else if (this.props.state.pageUrl[item.name]) {
            window.open(this.props.state.pageUrl[item.name] + el.code)
        }
        else if (item.name === "进度数据发布更新") {
            this.props.getDataPublish(el.code)
            return false;
        }
    }

    componentDidMount() {
        this.props.getBannerList();
        this.props.getNewOnline();
        this.props.getThemesList();
        this.timer = setInterval(this.props.scroll, this.props.state.interval);
        setInterval(this.props.scrollCustomer, this.props.state.intervalCustomer);
    }

    componentWillUpdate(nextProps, nextState) {
        let {step, stepCustomer, bannerList, customerList} = this.props.state;
        //边界判断：如果最新修改的STEP索引大于最大索引（说明此时已经是末尾了，不能在向后走了），我们让其立即回到（无动画）索引为1的位置
        if (step > bannerList.length) {
            this.props.initScroll();
        }
        //边界判断：如果最新修改的STEP索引大于最大索引（说明此时已经是末尾了，不能在向后走了），我们让其立即回到（无动画）索引为1的位置
        if (stepCustomer > customerList.length) {
            this.props.initScrollCustomer();
        }
    }

    componentDidUpdate() {
        //只有是从克隆的第一张立即切换到真实第一张后，我们才做如下处理：让其从当前第一张运动到第二张即可
        let {step, stepCustomer, speed, speedCustomer} = this.props.state;
        if (step === 0 && speed === 0) {
            //为啥要设置定时器延迟：CSS3的transition有一个问题（主栈执行的时候，短时间内遇到两次设置TRANSITION-DRRATION的代码，以最后一次设置的为主）
            let delayTimer = setTimeout(() => {
                clearTimeout(delayTimer);
                this.props.reScroll(step, speed);
            }, 0);
        }
        if (stepCustomer === 0 && speedCustomer === 0) {
            //为啥要设置定时器延迟：CSS3的transition有一个问题（主栈执行的时候，短时间内遇到两次设置TRANSITION-DRRATION的代码，以最后一次设置的为主）
            let delayTimer = setTimeout(() => {
                clearTimeout(delayTimer);
                this.props.reScrollCustomer(stepCustomer, speedCustomer);
            }, 0);
        }
    }

}

//需要渲染什么数据
function mapStateToProps(state) {
    return {
        state: state.home,
        stateHeader: state.header
    }
}

//需要触发什么行为
function mapDispatchToProps(dispatch) {
    return {
        getVersion: () => dispatch(action.getVersion()),
        // getEntryList: () => dispatch(action.getEntryList()),
        handleIndicateList: (key, value) => dispatch(actionHeader.handleKeyValue(key, value)),
        getBannerList: () => dispatch(action.getBannerPicList()),
        scroll: () => dispatch(action.scroll()),
        initScroll: () => dispatch(action.initScroll()),
        reScroll: (step, speed) => dispatch(action.reScroll(step, speed)),
        scrollTo: (step) => dispatch(action.scrollTo(step)),
        scrollCustomer: () => dispatch(action.scrollCustomer()),
        initScrollCustomer: () => dispatch(action.initScrollCustomer()),
        reScrollCustomer: (step, speed) => dispatch(action.reScrollCustomer(step, speed)),
        getDataPublish: (data) => dispatch(action.getDataPublish(data)),
        getNewOnline: () => dispatch(action.getNewOnline()),
        getThemesList: () => dispatch(action.getThemesList()),
        addUserAdvice: () => dispatch(action.addUserAdvice()),
        handleContactModal: (key, value) => dispatch(actionHeader.handleKeyValue(key, value)),
        handleClear: () => dispatch(actionHeader.handleClear()),
        handleIsAddUserAdviceSuccess: (key, value) => dispatch(actionHeader.handleKeyValue(key, value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
