import API from '../../api/api';
import * as type from "./action-type";
import { JSInfo } from "../../constant/jingsai-data";

export const handleShowModal = () => {
    return {
        type: type.SHOW_MODAL
    }
}
export const retValue = (key,value) => {
    return {
        type: type.SHOW_MODAL,
        value,
        key,
    }
}

//banner
export const scroll = () => {
    return {
        type: type.SCROLL
    }
}
export const reScroll = (step,speed) => {
    return {
        type: type.RE_SCROLL
    }
}
export const initScroll=()=>{
    return {
        type: type.SCROLL_INIT
    }
}
export const scrollTo=(step)=>{
    return {
        toStep:step,
        type: type.SCROLL_TO
    }
}

//典型用户
export const scrollCustomer = (step,speed) => {
    return {
        type: type.SCROLL_Customer
    }
}
export const reScrollCustomer = (step,speed) => {
    return {
        type: type.RE_SCROLL_Customer
    }
}
export const initScrollCustomer=()=>{
    return {
        type: type.SCROLL_INIT_Customer
    }
}

// 获取Version
export const getVersion = () => {
    // 返回函数，异步dispatch
    return async dispatch => {
        try{
            let result = await API.getVersion();
            dispatch({
                type: type.Get_Version,
                data: result
            })
        }
        catch(err){
            console.error(err);
        }
    }
}

// 获取entry
export const getEntryList = () => {
    // 返回函数，异步dispatch
    return async dispatch => {
        try{
            let result = await API.getEntryList();
            let data = result;
            dispatch({
                type: type.Get_EntryList,
                data: data
            })
        }
        catch(err){
            console.error(err);
        }
    }
}

// 获取banner列表，保存至redux
export const getBannerPicList = () => {
    // 返回函数，异步dispatch
    return async dispatch => {
        try{
            let result = await API.getBannerPicList();
            let data = result.data;
            let cloneData = data.slice(0);
            if(data.length > 0){
                cloneData.push(data[0]);
                cloneData.unshift(data[data.length - 1]);
            }

            dispatch({
                type: type.GET_BANNERLIST,
                data: data,
                cloneData: cloneData
            })
        }
        catch(err){
            console.error(err);
        }
    }
}
// 获取最新课题研究,用户报告,统计播报
export const getThemesList = () => {
    // 返回函数，异步dispatch
    return async dispatch => {
        try{
            const result = await API.getThemesList();
            const data = result || [];
            data.splice(2, 0, JSInfo);
            dispatch({
                type: type.Get_ThemesList,
                data: data
            })
        }
        catch(err){
            console.error(err);
        }
    }
}


// 获取最新上线
export const getNewOnline = () => {
    // 返回函数，异步dispatch
    return async dispatch => {
        try{
            let result = await API.getNewOnline();
            let data = result;
            dispatch({
                type: type.Get_NewOnline,
                data: data
            })
        }
        catch(err){
            console.error(err);
        }
    }
}

// 获取检索数值
export const getDataCount = () => {
    // 返回函数，异步dispatch
    return async dispatch => {
        try{
            let result = await API.post('/Home/GetDataCount');
            dispatch({
                type: type.Get_DataCount,
                data: result.data
            })
        }
        catch(err){
            console.error(err);
        }
    }
}
// 用户反馈
export const addUserAdvice = () => {
    // 返回函数，异步dispatch
    return async dispatch => {
        try{
            let result = await API.addUserAdvice();
            let data = result;
            dispatch({
                type: type.Add_UserAdvice,
                data: data
            })
        }
        catch(err){
            console.error(err);
        }
    }
}
//进度数据发布更新
export const getDataPublish = (data) => {
    let _url = '/DataPublish/GetNewDataUrl';
    // 返回函数，异步dispatch
    return async dispatch => {
        try{
            let result = await API.post(_url,data);
            if(result.data){
                window.open("./datapublish/monthResult?sid="+result.data)
            }
            // dispatch({
            //     type: type.getSubjectStudy,
            //     subjectStudy: result.data
            // })
        }
        catch(err){
            console.error(err);
        }
    }
}
//登录状态判断
export const getLoginStatus=()=>{
    return async dispatch => {
        try{
            let {data} = await API.post('/Auth/GetOnlineUser')
            dispatch({
                type:type.Get_LoginStatus,
                data:data
            })
        }catch(err){
            console.error(err)
        }
    }
}

// 获取检索提示词
export const getIndicateList = (data) => {
    // 返回函数，异步dispatch
    return async dispatch => {
        try{
            if (data.kd === undefined || data.kd.trim() === '') {
                return false;
            }
            else{
                let url = '/Home/GetIndicateList';
                let result = await API.post(url,data);
                dispatch({
                    type: type.Get_IndicateList,
                    data: result.data
                })
            }
        }
        catch(err){
            console.error(err);
        }
    }
}
