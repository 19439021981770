import React, {Component} from "react";
import {connect} from 'react-redux';

import * as action from '../../store/shared/header/action';
import envconfig from '../../envconfig/envconfig';

import Modal from '../shared/modal';
import style from '../../assets/css/shared/header.module.scss';
import '../../assets/css/shared/top.scss';
import iconSearchUrl from '../../assets/images/home/icon-search.png';
import cnki_logo from '../../assets/images/shared/cnki_logo.png'
import cookieUtils from "../../utils/cookieUtils";
import { debounce } from "../../utils/utils";

class Header extends Component {
    constructor(props) {
        super(props);
    }

    //初始化
    init() {
        let _this = this;
        let version = envconfig.version;
        if (version === 'mirror') {
            let userName = cookieUtils.getCookie('Ecp_loginusername');
            _this.props.handleKeyValue('userName', userName);
            _this.props.getLoginMode();
        }
    }

    render() {
        const state = this.props.state;
        const {searchList, checkedSearch} = state;
        const isLogin = !!cookieUtils.getCookie('LID')
        const version = envconfig.version;
        let trades = this.props.state.industryList;
        let tradeList = [];
        for (let i = 0; i < trades.length; i += 12) {
            tradeList.push(trades.slice(i, i + 12));
        }
        const hideSearch = window.location.pathname === '' || window.location.pathname === '/' || window.location.pathname === '/home' || window.location.pathname === '/intelligentAnalysis'
        return (
            (version === 'center' || version === 'overseas')
                ?
                <div>
                    <div className={style['header']}>
                        <div className={style['top']}>
                            <div className={style['left']}>
                                <div onMouseOver={() => this.props.handleMenuShow('isTradeMenuShow', true)}
                                     onMouseOut={() => this.props.handleMenuShow('isTradeMenuShow', false)}
                                     className={'btn-group ' + style['btn-group']}>
                                    <button
                                        className={"btn btn-success dropdown-toggle " + style['btn'] + ' ' + style['btn-success']}
                                        data-toggle="dropdown">
                                        行业版 <span className={"caret " + style['caret']}></span>
                                    </button>
                                    <div
                                        className={(this.props.state.isTradeMenuShow ? 'show' : 'hide') + ' dropdown-menu ' + style['dropdown-menu'] + ' ' + style['trade-menu']}>
                                        <div className={style['trade-box']}>
                                            {
                                                tradeList.map((rows, index) => {
                                                    return (
                                                        <ul key={index} className={style['list']}>
                                                            {
                                                                rows.map((col, index) => {
                                                                    return (
                                                                        <li key={index}>
                                                                            <span className={'dot ' + style['dot']}></span>
                                                                            <a href={'./trade/home?zcode=' + col.industryCode}
                                                                               target={'_blank'}>{col.industryName}</a>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div onMouseOver={() => this.props.handleMenuShow('isAreaMenuShow', true)}
                                     onMouseOut={() => this.props.handleMenuShow('isAreaMenuShow', false)}
                                     className={'btn-group ' + style['btn-group']}>
                                    <button
                                        className={"btn btn-warning dropdown-toggle " + style['btn'] + ' ' + style['btn-warning']}
                                        data-toggle="dropdown">
                                        地域版 <span className={"caret " + style['caret']}></span>
                                    </button>
                                    <div
                                        className={(this.props.state.isAreaMenuShow ? 'show' : 'hide') + ' dropdown-menu ' + style['dropdown-menu'] + ' ' + style['shared-menu']}>
                                        <div className={style['area-box']}>
                                            <div className={style['left']}>
                                                {
                                                    this.props.state.areaGroups.map((item, index) => {
                                                        let list = [];
                                                        let areas = this.props.state.areas;
                                                        let startIndex = item.start.toLowerCase().charCodeAt();
                                                        let endIndex = item.end.toLowerCase().charCodeAt();
                                                        for (let i = 0; i < areas.length; i++) {
                                                            if (areas[i].PYName[0].charCodeAt() >= startIndex && areas[i].PYName[0].charCodeAt() <= endIndex) {
                                                                list.push(areas[i]);
                                                            }
                                                        }
                                                        let rows = [];
                                                        if (index == state.areaGroups.length - 1) {
                                                            for (let i = 0; i < list.length; i += 2) {
                                                                rows.push(list.slice(i, i + 2));
                                                            }
                                                        } else {
                                                            for (let i = 0; i < list.length; i += 3) {
                                                                rows.push(list.slice(i, i + 3));
                                                            }
                                                        }
                                                        return (
                                                            <div key={index} className={style['group']}>
                                                                <label>
                                                                    {item.start}-{item.end}
                                                                </label>
                                                                <ul className={style['list']}>
                                                                    {
                                                                        rows.map((cols, index) => {
                                                                            return (
                                                                                <li key={index}>
                                                                                    {
                                                                                        cols.map((col, index) => {
                                                                                            return (
                                                                                                <a href={'./area/home?dcode=' + col.DCode}
                                                                                                   target={'_blank'}
                                                                                                   key={index}>{col.AreaName}</a>)
                                                                                        })
                                                                                    }
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                            <span className={style['tip']}>注：不含港澳台</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={style['right'] + ' ' + (version === 'overseas' ? style['overseas-right'] : '')}>
                                <a href={version === 'overseas' ? 'https://chn.oversea.cnki.net/index/' : 'https://cnki.net'} target={'_blank'}>知网首页</a>
                                <a href={'./myVideo'} style={{display:(version==='center' && isLogin)?'block':'none'}} target={'_blank'}>使用指南</a>
                            </div>
                        </div>
                        <div className={style['bottom']} style={{display: state.isDataCustom ? "none" : "block"}}>
                            <img className={style['logo']} src={cnki_logo}/>
                            <h4 className={style['logo-title']} onClick={() => {window.location.href = envconfig.rootUrl}}>中国经济社会大数据研究平台</h4>
                            <nav>
                                {
                                    this.props.state.nav.map((item, index) => {
                                        if (item.sub) {
                                            return (
                                                <div key={index} className={'btn-group ' + style['btn-group']}
                                                     onMouseOver={(e) => {
                                                         this.mouseOver(e, index)
                                                     }} onMouseOut={(e) => {
                                                    this.mouseOut(e, index)
                                                }}>
                                                    <a href={item.url}
                                                       className={"btn dropdown-toggle " + style['btn'] + ' ' + (this.props.state.current === index ? style['current'] : '')}
                                                       data-toggle="dropdown" target={'_blank'}>
                                                        {item.name} <span className="caret"></span>
                                                    </a>
                                                    <ul className={'dropdown-menu ' + style['dropdown-menu']}>
                                                        {
                                                            item.sub.map((subItem, subIndex) => {
                                                                if (version === 'overseas' && subItem.notOverseas) {
                                                                    return null
                                                                }
                                                                return (
                                                                    <li key={subIndex}><a href={subItem.url}>{subItem.name}</a></li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            )
                                        }
                                        return (<a key={index} href={item.url}
                                                   className={this.props.state.current === index ? style['current'] : ''}>{item.name}</a>)
                                    })
                                }
                            </nav>


                            <div className={style['search'] + ' ' + (hideSearch ? 'hide' : '')}>
                                <div className={style['dropdown']}
                                     onClick={() => this.props.handleKeyValue('isPanelShow', !state.isPanelShow)}>
                                    <div className={style['search-type']}>{checkedSearch.title}</div>
                                    <ul className={`dropdown-menu ${style['dropdown-list']} ${state.isPanelShow ? 'show' : 'hide'}`}>
                                        {
                                            searchList.map((item, index) => {
                                                if (envconfig.version === 'overseas' && index === 3) {
                                                    return null
                                                }
                                                return (
                                                    <li key={index}
                                                        className={`${checkedSearch.title == item.title ? style['current'] : ''}`}
                                                        onClick={() => this.handlePanel(item)}>{item.title}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className={style['keywrod']}>
                                    <input
                                        className={style['search-input'] + ' ' + (version === 'overseas' ? style['overseas'] : '')} value={state.kd}
                                        onChange={(e) => this.props.handleKeyValue("kd", e.target.value)}
                                        onKeyDown={(e) => {
                                            this.handleEnterKey(e, state.kd)
                                        }}
                                        type="text"
                                        onBlur={(e) => this.onBlur(e)}
                                        // placeholder={'检索指标、条目、资料和资讯'}
                                        onKeyUp={debounce((e) => this.getIndicateList(e),700)}
                                    />
                                    <i onClick={() => this.toSearch()}></i>
                                    <ul
                                        className={(state.isIndicateListShow && state.indicateList.length > 0 ? 'show' : 'hide') + ' dropdown-menu ' + style['dropdown-menu']}
                                        onMouseDown={(e) => e.preventDefault()}
                                    >
                                        {
                                            this.props.state.indicateList.map((item, index) => {
                                                if (envconfig.version === 'overseas' && item.name === '统计资讯') {
                                                    return null
                                                }
                                                return (
                                                    <li key={index}>
                                                        <div className={style['module-header']}>
                                                            <svg className={style["icons"]} aria-hidden="true">
                                                                <use xlinkHref={this.getIcons(item)} />
                                                            </svg>
                                                            <span>{item.name}</span>
                                                        </div>
                                                        <ul className={style['module-body']}>
                                                            {
                                                                item.searchPointOutItems.map((el, index) => {
                                                                    return (
                                                                        <li key={index}>
                                                                            <a href={this.getUrl(item.name, el)}
                                                                               title={el.indicate}>{el.indicate}</a>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                {version === 'center' ? (
                                    <a
                                        className={style['ai']}
                                        onMouseEnter={() => this.props.handleKeyValue('showAiTip', true)}
                                        onMouseLeave={() => this.props.handleKeyValue('showAiTip', false)}
                                        href={`./intelligentAnalysis?kd=${state.kd.trim()}`}
                                    >
                                        <div className={style['ai-btn']}>
                                            <span>智能分析</span>
                                        </div>
                                        {state.showAiTip && (
                                            <div className={style['tooltips']}>
                                                支持语句搜索，如可输入“北京近五年GDP”
                                            </div>
                                        )}
                                    </a>
                                ) : null}
                            </div>


                            {/* <div className={style['search-box'] + ' dropdown'}>
                            <input
                                className={style['search-input']}
                                id="searchInput"
                                maxLength={50}
                                placeholder={'检索指标、条目、资料和资讯'}
                                onKeyUp={debounce((e) => this.getIndicateList(e),700)}
                                onBlur={() => this.props.handleIndicateList('isIndicateListShow', false)}
                            />
                            <img className={style['icon-search']} src={iconSearchUrl} onClick={(e) => this.toSearch()} />
                            <ul
                                className={(state.isIndicateListShow && state.indicateList.length > 0 ? 'show' : 'hide') + ' dropdown-menu ' + style['dropdown-menu']}
                                onMouseDown={(e) => e.preventDefault()}
                            >
                                {
                                    this.props.state.indicateList.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <div className={style['module-header']}>
                                                    <svg className={style["icons"]} aria-hidden="true">
                                                        <use xlinkHref={this.getIcons(item)} />
                                                    </svg>
                                                    <span>{item.name}</span>
                                                </div>
                                                <ul className={style['module-body']}>
                                                    {
                                                        item.searchPointOutItems.map((el, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    <a href={this.getUrl(item.name, el)}
                                                                       title={el.indicate}>{el.indicate}</a>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div> */}
                        </div>
                        <Modal/>
                    </div>
                    <div style={{ height: '130px'}}/>
                </div>
                :
                <div>
                    <div className={style['header'] + ' ' + style['header-mirror']}>
                        <div className={style['top']}>
                            <div className={style['left']}>
                                <div onMouseOver={() => this.props.handleMenuShow('isTradeMenuShow', true)}
                                     onMouseOut={() => this.props.handleMenuShow('isTradeMenuShow', false)}
                                     className={'btn-group ' + style['btn-group']}>
                                    <button
                                        className={"btn btn-success dropdown-toggle " + style['btn'] + ' ' + style['btn-success']}
                                        data-toggle="dropdown">
                                        行业版 <span className={"caret " + style['caret']}></span>
                                    </button>
                                    <div
                                        className={(this.props.state.isTradeMenuShow ? 'show' : 'hide') + ' dropdown-menu ' + style['dropdown-menu'] + ' ' + style['trade-menu']}>
                                        <div className={style['trade-box']}>
                                            {
                                                tradeList.map((rows, index) => {
                                                    return (
                                                        <ul key={index} className={style['list']}>
                                                            {
                                                                rows.map((col, index) => {
                                                                    return (
                                                                        <li key={index}>
                                                                            <span className={'dot ' + style['dot']}></span>
                                                                            <a href={'./trade/home?zcode=' + col.industryCode}
                                                                               target={'_blank'}>{col.industryName}</a>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div onMouseOver={() => this.props.handleMenuShow('isAreaMenuShow', true)}
                                     onMouseOut={() => this.props.handleMenuShow('isAreaMenuShow', false)}
                                     className={'btn-group ' + style['btn-group']}>
                                    <button
                                        className={"btn btn-warning dropdown-toggle " + style['btn'] + ' ' + style['btn-warning']}
                                        data-toggle="dropdown">
                                        地域版 <span className={"caret " + style['caret']}></span>
                                    </button>
                                    <div
                                        className={(this.props.state.isAreaMenuShow ? 'show' : 'hide') + ' dropdown-menu ' + style['dropdown-menu'] + ' ' + style['shared-menu']}>
                                        <div className={style['area-box']}>
                                            <div className={style['left']}>
                                                {
                                                    this.props.state.areaGroups.map((item, index) => {
                                                        let list = [];
                                                        let areas = this.props.state.areas;
                                                        let startIndex = item.start.toLowerCase().charCodeAt();
                                                        let endIndex = item.end.toLowerCase().charCodeAt();
                                                        for (let i = 0; i < areas.length; i++) {
                                                            if (areas[i].PYName[0].charCodeAt() >= startIndex && areas[i].PYName[0].charCodeAt() <= endIndex) {
                                                                list.push(areas[i]);
                                                            }
                                                        }
                                                        let rows = [];
                                                        if (index == state.areaGroups.length - 1) {
                                                            for (let i = 0; i < list.length; i += 2) {
                                                                rows.push(list.slice(i, i + 2));
                                                            }
                                                        } else {
                                                            for (let i = 0; i < list.length; i += 3) {
                                                                rows.push(list.slice(i, i + 3));
                                                            }
                                                        }
                                                        return (
                                                            <div key={index} className={style['group']}>
                                                                <label>
                                                                    {item.start}-{item.end}
                                                                </label>
                                                                <ul className={style['list']}>
                                                                    {
                                                                        rows.map((cols, index) => {
                                                                            return (
                                                                                <li key={index}>
                                                                                    {
                                                                                        cols.map((col, index) => {
                                                                                            return (
                                                                                                <a href={'./area/home?dcode=' + col.DCode}
                                                                                                   target={'_blank'}
                                                                                                   key={index}>{col.AreaName}</a>)
                                                                                        })
                                                                                    }
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <span className={style['tip']}>注：不含港澳台</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={style['right']}>
                                {
                                    state.userName ?
                                        <div className={style['login']}>
                                            <span className={'user'}>{state.userName}</span>
                                            <a className={style['logout']}
                                               href={envconfig.rootUrl + '/api/csyd/Auth/Logout?returnurl=' + encodeURIComponent(window.location.href)}>登出</a>
                                        </div>
                                        :
                                        <a href={state.loginMode == 0 ? envconfig.loginUrl : envconfig.loginUrlLocal}>登录</a>
                                }
                            </div>
                        </div>
                        <div className={style['bottom']} style={{display: state.isDataCustom ? "none" : "block"}}>
                            <img className={style['logo']}/>
                            <h4 className={style['logo-title']} onClick={() => {window.location.href = envconfig.rootUrl}}>中国经济社会大数据研究平台</h4>
                            <nav>
                                {
                                    this.props.state.nav.map((item, index) => {
                                        if (item.sub) {
                                            return (
                                                <div key={index} className={'btn-group ' + style['btn-group']}
                                                     onMouseOver={(e) => {
                                                         this.mouseOver(e, index)
                                                     }} onMouseOut={(e) => {
                                                    this.mouseOut(e, index)
                                                }}>
                                                    <a href={item.url}
                                                       className={"btn dropdown-toggle " + style['btn'] + ' ' + (this.props.state.current === index ? style['current'] : '')}
                                                       data-toggle="dropdown" target={'_blank'}>
                                                        {item.name} <span className="caret"></span>
                                                    </a>
                                                    <ul className={'dropdown-menu ' + style['dropdown-menu']}>
                                                        {
                                                            item.sub.map((item, index) => {
                                                                if (item.name === '数据智能分析平台') {
                                                                    return <li key={index}><a href={item.url} target='_blank'>{item.name}</a></li>
                                                                }
                                                                return (
                                                                    <li key={index}><a href={item.url}>{item.name}</a></li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            )
                                        }
                                        return (<a key={index} href={item.url}
                                                   className={this.props.state.current === index ? style['current'] : ''}>{item.name}</a>)
                                    })
                                }
                            </nav>
                            <div className={style['search-box'] + ' dropdown'}>
                                <input
                                    className={style['search-input']}
                                    id="searchInput"
                                    maxLength={50}
                                    placeholder={'检索指标、条目、资料和资讯'}
                                    onKeyUp={debounce((e) => this.getIndicateList(e),700)}
                                    onBlur={() => this.props.handleIndicateList('isIndicateListShow', false)}
                                />
                                <img className={style['icon-search']} src={iconSearchUrl} onClick={(e) => this.toMirrorSearch()} />
                                <ul
                                    className={(state.isIndicateListShow && state.indicateList.length > 0 ? 'show' : 'hide') + ' dropdown-menu ' + style['dropdown-menu']}
                                    onMouseDown={(e) => e.preventDefault()}
                                >
                                    {
                                        this.props.state.indicateList.map((item, index) => {
                                            return (
                                                <li key={index}>
                                                    <div className={style['module-header']}>
                                                        <svg className={style["icons"]} aria-hidden="true">
                                                            <use xlinkHref={this.getIcons(item)} />
                                                        </svg>
                                                        <span>{item.name}</span>
                                                    </div>
                                                    <ul className={style['module-body']}>
                                                        {
                                                            item.searchPointOutItems.map((el, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        <a href={this.getUrl(item.name, el)}
                                                                           title={el.indicate}>{el.indicate}</a>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <Modal/>
                    </div>
                    <div style={{ height: '130px'}}/>
                </div>
        );
    }

    componentWillMount() {
        this.init();
        this.props.getVersion();
        this.props.getIndustryList();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let modals = document.getElementsByClassName('modal');
        for (let i = 0; i < modals.length; i++) {
            modals[i].removeAttribute("style");
        }
    }

    getIcons(item) {
        switch (item.name) {
            case "统计指标":
                return "#icon-folder-chart-line"
            case "条目题名":
                return "#icon-t-box-line"
            case "统计资料":
                return "#icon-stack-line"
            case "统计资讯":
                return "#icon-newspaper-line"
            default:
                return ""
        }
    }

    getUrl(val, el) {
        switch (val) {
            case "统计指标":
                return "./valueSearch/index?ky=" + encodeURIComponent(el.indicate)
            case "条目题名":
                return "./itemSearch/index?ky=" + encodeURIComponent(el.indicate)
            case "统计资料":
                return "./statisticalData/index?ky=" + encodeURIComponent(el.indicate)
            case "统计资讯":
                return "./statisticalInfo/index?ky=" + encodeURIComponent(el.indicate)
            default:
                return ""
        }
    }

    mouseOver(e, index) {
        let ul = document.querySelectorAll('nav')[0].children[index].querySelector('ul');
        ul.style.display = 'block'

    }

    mouseOut(e, index) {
        let ul = document.querySelectorAll('nav')[0].children[index].querySelector('ul');
        ul.style.display = 'none'
    }

    toMirrorSearch(e) {

        let input = document.getElementById("searchInput");
        // if (input.value) {
            if (input.value.trim().length <= 50) {
                window.location.href = envconfig.rootUrl + "/valueSearch/index?ky=" + encodeURIComponent(input.value.trim())
            }
            else {
                alert('字符过长');
            }
        // }
        // else {
        //     window.location.href = envconfig.rootUrl + "/valueSearch/index?ky=GDP"
        // }
    }

    onBlur(e) {
        this.props.handleIndicateList('isIndicateListShow', false)
    }

    handleEnterKey(event) {
        let e = event || window.event
        if (e && e.keyCode === 13) {
            this.toSearch()
        }
    }

    toSearch() {
        const {checkedSearch, kd} = this.props.state
        // window.location.href = `${checkedSearch.url}${kd.trim() ? kd : 'GDP'}`
        window.location.href = `${checkedSearch.url}${kd.trim()}`
    }

    handlePanel(item) {
        this.props.handleKeyValue('checkedSearch', {...item})
        this.props.handleKeyValue('isPanelShow', false)
    }

    getIndicateList(e) {
        if (e && e.keyCode === 13) {
            if (envconfig.version === 'mirror') {
                this.toMirrorSearch()
            } else {
                this.toSearch()
            }
        } else {
            const value = e.target.value;
            if (value?.trim().length <= 0) {
                this.props.handleIndicateList('isIndicateListShow', false)
                return
            }
            let data = {
                kd: e.target.value.trim()
            }
            if (e.target.value.trim().length > 0) {
                this.props.getIndicateList(data)
            }
        }
    }
}

//需要渲染什么数据
const mapStateToProps = (state) => {
    return {
        state: state.header,
    }
}

//需要触发什么行为
function mapDispatchToProps(dispatch) {
    return {
        getVersion: () => dispatch(action.getVersion()),
        getLoginMode: () => dispatch(action.getLoginMode()),
        handleKeyValue: (key, value) => dispatch(action.handleKeyValue(key, value)),
        handleMenuShow: (key, value) => dispatch(action.handleKeyValue(key, value)),
        getIndustryList: () => dispatch(action.getIndustryList()),
        getIndicateList: (data) => dispatch(action.getIndicateList(data)),
        handleIndicateList: (key, value) => dispatch(action.handleKeyValue(key, value)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
