import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from '../../serviceWorker';
import store from '../../store/home/store';
import envconfig from '../../envconfig/envconfig';
import '../../assets/css/shared/base.scss';
import Home from "../home/home";
import HomeMirror from "../home/homeMirror";
import HomeOverseas from "../home/homeOverseas";
import Header from '../shared/header';
import Footer from "../shared/footer";
import 'babel-polyfill'
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
ReactDOM.render((
    <Provider store={store}>
        <BrowserRouter>
            <Header />
            {
                envconfig.version == 'center'
                ?<Home />
                : envconfig.version == 'mirror'
                ? <HomeMirror />
                : <HomeOverseas />
            }
            <Footer />
        </BrowserRouter>
    </Provider>
), document.getElementById('root'));

serviceWorker.unregister();
