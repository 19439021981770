
import React from 'react';

import style from '../../assets/css/shared/footerOverseas.module.scss'

class FooterOverseas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className={style['hwfooter'] + ' clearfix'}>
                <div className={style['hwfoot-top']}>
                    <ul className={style['hwcontent']}>
                        <li><b>关于我们</b><a target='_blank'
                                          href='//oversea.cnki.net/index/Support/gb/Introduction.html'>公司简介</a><a
                            target='_blank' href='//oversea.cnki.net/index/Support/gb/Project.html '>CNKI工程</a><a
                            target='_blank'
                            href='//oversea.cnki.net/index/products/gb/index.html'>产品与服务</a></li>
                        <li><b>服务中心</b><a target='_blank' href='//chn.oversea.cnki.net/index/gb/gb-manual.html'>使用手册</a><a
                            target='_blank'
                            href='https://oversea.cnki.net/index/Support/gb/faq.html'>常见问题</a><a target='_blank'
                                                                                                 href='https://cajviewer.oversea.cnki.net/chn/'>CAJviewer下载</a>
                        </li>
                        <li style={{marginLeft: '20px'}}><b style={{marginBottom: '44px'}}></b><a target='_blank'
                                                                                            href='https://chn.oversea.cnki.net/sendmail/gb/trials.html'>产品试用</a><a
                            target='_blank'
                            href='https://chn.oversea.cnki.net/sendmail/gb/feedback.html'>建议反馈</a><a target='_blank'
                                                                                                     href='//oversea.cnki.net/index/Support/gb/contactus.html'>联系我们</a>
                        </li>
                        <li style={{width: '155px'}}><b>合作伙伴</b><a target='_blank'
                                                                href='//www.spc.jst.go.jp'>中国・アジア研究論文データベースカート文字サイズ</a><a
                            target='_blank' href='http://www.njupco.com/'>南京大学出版社</a></li>
                        <li style={{marginLeft: '20px'}}><b style={{marginBottom: '44px'}}></b><a target='_blank'
                                                                                            href='http://www.crup.cn/'>中国人民大学出版社</a><a
                            target='_blank' href='https://www.pmph.com/'>人民卫生出版社</a><a
                            target='_blank' href='//www.jnupress.com/'>暨南大学出版社</a></li>
                        <li><b>全球代理商</b><a href='//oversea.cnki.net/index/GlobalPartners/homepage.html'
                                           target='_blank'>代理商信息</a><a
                            href='https://drive.google.com/file/d/1sAlXVkx8B_Ii8Vr-9h4YDvuax9XQYHWa/view?usp=sharing'
                            target='_blank'>代理商权益</a><a href='//cnki2019.mikecrm.com/OLFXwkq' target='_blank'>成为代理商</a>
                        </li>
                        <li className={style['hwweibo']}><a><img
                            src='https://oversea.cnki.net/index/Support/gb/images/logo_tx1.png'
                            alt='微信'/><span>微信</span><i className={style['code-wx']}>
                            <div className={style['arrow']}></div>
                            <img src='https://oversea.cnki.net/index/Support/gb/images/code-wx.png'/>
                        </i></a><a target='_blank' href='//twitter.com/CNKIOverseas'><img
                            src='https://oversea.cnki.net/index/Support/gb/images/logo_tx2.png'
                            alt=''/><span>Twitter </span></a><a
                            target='_blank' href='//www.facebook.com/oversea.cnki.net/'><img
                            src='https://oversea.cnki.net/index/Support/gb/images/logo_tx3.png'
                            alt=''/><span>Facebook</span></a><a
                            target='_blank' href='//www.youtube.com/channel/UCfdsMjnhKLlaEYV2y-yOjeQ'><img
                            src='https://oversea.cnki.net/index/Support/gb/images/logo_tx4.png'
                            alt=''/><span>Youtube</span></a>
                        </li>
                    </ul>
                </div>
                <div className={style['hwfoot-bot'] + ' clearfix'}>
                    <div className={style['t']}><img
                        src='https://piccache.oversea.cnki.net/2022/oversea/logo/mwhite.png'
                        alt='中国知网'/>
                        <p className={style['mid']}><span> © 1998-<em id='timeShow'>{new Date().getFullYear()}</em> 中国知网（CNKI） </span></p>
                        <p className={style['right']}><a id='___szfw_logo___'
                                                         href='//ss.knet.cn/verifyseal.dll?sn=e13111111010043364isll000000&amp;a=1&amp;pa=0.08881110103584755'
                                                         target='_blank'><img alt='可信网站'
                                                                              src='//piccache.oversea.cnki.net/2022/kdn/index/kns7/nimages/kxwz.jpg'
                                                                              height='33' width='86'/></a></p>
                    </div>
                    <div className={style['b']}><span>京 ICP 证 040431 号<a target='_blank'
                                                                href='//piccache.oversea.cnki.net/2022/index/images/gb/271.jpg'> 网络出版服务许可证 (总)网出证(京)字第 271 号</a><a
                        target='_blank' href='//piccache.oversea.cnki.net/2022/index/images/gb/170001.jpg'>出版物经营许可证</a>京公网安备
        11010802020460 号</span><span>《中国学术期刊（光盘版）》电子杂志社有限公司</span><span>KDN 平台基础技术由 KBASE 11.0 提供.</span></div>
                </div>
            </div>
        );
    }
}

export default FooterOverseas;

