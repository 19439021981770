export default [
  {
    "name": "中国指数",
    "url": "./businessIndex/index"
  },
  {
    "name": "进度数据发布",
    "url": "./datapublish/month"
  },
  {
    "name": "统计资讯",
    "url": "./statisticalInfo/index?ky=GDP"
  },
  {
    "name": "指标数值检索",
    "url": "./valueSearch/index?ky=GDP"
  }
]
