import React, {Component} from "react";
import {connect} from 'react-redux';

import * as action from "../../store/home/action";
import * as actionHeader from "../../store/shared/header/action";
import { debounce } from '../../utils/utils'

import iconEyeUrl from '../../assets/images/home/icon-eye.png';
import iconHotUrl from '../../assets/images/home/icon-hot.png';
import bannerImg from '../../assets/images/home/overseas/banner.jpg'

import { panelList0 } from '../../constant/entry-list.js'
import style from '../../assets/css/home/homeOverseas.module.scss';

class HomeOverseas extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.props.getBannerList();
        this.props.getNewOnline();
        this.props.getThemesList();
        this.props.getLoginStatus().then(() => {
            if (this.props.state.isLogin) {
                this.props.getDataCount();
            }
        })
    }

    onBlur(e) {
        if (!e.target.value) {
            this.props.retValue('isFocus', false)
        }
        this.props.handleIndicateList('isIndicateListShow', false)
    }

    handleEnterKey(event) {
        let e = event || window.event
        if (e && e.keyCode === 13) {
            this.toSearch()
        }
    }

    toSearch() {
        const {checkedSearch, kd} = this.props.state
        window.location.href = `${checkedSearch.url}${kd.trim()}`
    }

    handlePanel(item) {
        this.props.retValue('checkedSearch', {...item})
        this.props.retValue('isPanelShow', false)
    }

    toResult(item, el) {
        if (item.name === "统计资讯") {
            window.open(this.props.state.pageUrl["统计资讯详情"] + el.code)
        } else if (this.props.state.pageUrl[item.name]) {
            window.open(this.props.state.pageUrl[item.name] + el.code)
        } else if (item.name === "进度数据发布更新") {
            this.props.getDataPublish(el.code)
            return false;
        }
    }

    getIndicateList(e) {
        if (e && e.keyCode === 13) {
            this.toSearch()
        } else {
            const value = e.target.value;
            if (value?.trim().length <= 0) {
                this.props.handleIndicateList('isIndicateListShow', false)
                return
            }
            let data = {
                kd: e.target.value.trim()
            }
            if (e.target.value.trim().length > 0) {
                this.props.getIndicateList(data)
            }
        }
    }

    getIcons(item) {
        switch (item.name) {
            case "统计指标":
                return "#icon-folder-chart-line"
            case "条目题名":
                return "#icon-t-box-line"
            case "统计资料":
                return "#icon-stack-line"
            case "统计资讯":
                return "#icon-newspaper-line"
            default:
                return ""
        }
    }

    getUrl(val, el) {
        switch (val) {
            case "统计指标":
                return "./valueSearch/index?ky=" + encodeURIComponent(el.indicate)
            case "条目题名":
                return "./itemSearch/index?ky=" + encodeURIComponent(el.indicate)
            case "统计资料":
                return "./statisticalData/index?ky=" + encodeURIComponent(el.indicate)
            case "统计资讯":
                return "./statisticalInfo/index?ky=" + encodeURIComponent(el.indicate)
            default:
                return ""
        }
    }

    render() {
        const { state, stateHeader } = this.props;
        const { searchList, checkedSearch, dataCount, themeList, newOnline } = state;
        return (
            <div className={'container'}>
                <div className={style['banner']}>
                    <img src={bannerImg} alt=""/>
                    <div className={style['search']}>
                        <div className={style['dropdown']}
                             onClick={() => this.props.retValue('isPanelShow', !state.isPanelShow)}>
                            <div className={style['search-type']}>{checkedSearch.title}</div>
                            <ul className={`dropdown-menu ${style['dropdown-list']} ${state.isPanelShow ? 'show' : 'hide'}`}>
                                {
                                    searchList.map((item, index) => {
                                        if (index === 3) {
                                            return null
                                        }
                                        return (
                                            <li key={index}
                                                className={`${checkedSearch.title == item.title ? style['current'] : ''}`}
                                                onClick={() => this.handlePanel(item)}>{item.title}</li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className={style['keywrod']}>
                            <p className={style['placeholder']}
                               style={{display: !state.isFocus && dataCount.index ? 'block' : 'none'}}>
                                <span>{dataCount.index}</span>个指标，<span>{dataCount.value}</span>笔数值数据，<span>{dataCount.item}</span>条条目数据，<span>{dataCount.kind}</span>种、<span>{dataCount.book}</span>册统计资料
                            </p>
                            <input
                                className={style['search-input']} value={state.kd}
                                onChange={(e) => this.props.retValue("kd", e.target.value)}
                                onKeyDown={(e) => {
                                    this.handleEnterKey(e, state.kd)
                                }}
                                type="text"
                                onFocus={() => this.props.retValue('isFocus', true)}
                                onKeyUp={debounce((e) => this.getIndicateList(e),700)}
                                onBlur={(e) => this.onBlur(e)}
                            />
                            <i onClick={() => this.toSearch()}></i>
                            <ul
                                className={(state.isIndicateListShow && state.indicateList.length > 0 ? 'show' : 'hide') + ' dropdown-menu ' + style['dropdown-menu']}
                                onMouseDown={(e) => e.preventDefault()}
                            >
                                {
                                    this.props.state.indicateList.map((item, index) => {
                                        if (item.name === '统计资讯') {
                                            return null
                                        }
                                        return (
                                            <li key={index}>
                                                <div className={style['module-header']}>
                                                    <svg className={style["icons"]} aria-hidden="true">
                                                        <use xlinkHref={this.getIcons(item)}/>
                                                    </svg>
                                                    <span>{item.name}</span>
                                                </div>
                                                <ul className={style['module-body']}>
                                                    {
                                                        item.searchPointOutItems.map((el, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    <a href={this.getUrl(item.name, el)}
                                                                       title={el.indicate}>{el.indicate}</a>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={'wrap'}>
                    <div className={'main ' + style['main']}>
                        <div className={'panel ' + style['panel'] + ' ' + style['panel-1']}>
                            {panelList0.map((item, index) => {
                                return (
                                    <div key={`card_${index}`} className={style['card-item']}>
                                        <span className={style['card-title']}>{item.title}</span>
                                        <ul className={style['list-wrap']}>
                                            {item.list.map(cItem => {
                                                if (cItem.notOverseas) return null
                                                return (
                                                    <li key={cItem.url}>
                                                        <a href={cItem.url}>{cItem.name}</a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                )
                            })}
                        </div>

                        <div className={'panel ' + style['panel'] + ' ' + style['panel-2']}>
                            <div className={style['card-item-1']}>
                                <span className={style['card-title']}>地域版</span>
                                <ul className={style['list-wrap']}>
                                    {state.areas.map(item => (
                                        <li key={item.DCode}>
                                            <a href={'./area/home?dcode=' + item.DCode}
                                               target={'_blank'}>{item.AreaName}</a>
                                        </li>
                                    ))}
                                </ul>
                                <span className={style['tips']}>注：不含港澳台</span>
                            </div>
                            <div className={style['card-item-2']}>
                                <span className={style['card-title']}>行业版</span>
                                <ul className={style['list-wrap']}>
                                    {stateHeader.industryList.map(item => (
                                        <li key={item.industryCode}>
                                            <a href={'./trade/home?zcode=' + item.industryCode} target={'_blank'}>
                                                {item.industryName}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className={'panel ' + style['panel'] + ' ' + style['panel-3']}>
                            <ul className={style['panel-body']}>
                                {
                                    themeList.map((item, index) => {
                                        if (index === 0) {
                                            return (
                                                <li key={index} className={style['panel-box-0']}
                                                    style={{display: index === 3 ? 'none' : 'normal'}}>
                                                    <h5 className={style['header']}>
                                                        <span className={style['panel-title']}>{item.resume}</span>
                                                        <a href={this.props.state.pageUrl[item.resume] + "/index"}
                                                           target={'_blank'}></a>
                                                    </h5>
                                                    <div className={style['left']}>
                                                        <img src={item.picUrl} alt='图片加载失败'/>
                                                        <a title={item.title} className={style['title']} target="_blank"
                                                           href={this.props.state.pageUrl[item.resume] + "/article?id=" + item.id}>
                                                            {item.title ? (item.title.length < 16 ? item.title : item.title.substring(0, 16) + '...') : ''}
                                                            <div className={style['log-count']}>
                                                                <img src={iconEyeUrl}/>
                                                                {item.logCount}
                                                            </div>
                                                        </a>
                                                        <p>
                                                            {item.contents ? item.contents.length < 48 ? item.contents : (item.contents.substring(0, 48) + '...') : ''}
                                                            <span
                                                                className={style['sp-time']}>{item.createTime ? item.createTime.substring(0, 10).replace(/-/g, ".") : ''}</span>
                                                        </p>
                                                    </div>
                                                    <ul className={style['hot-list']}>
                                                        {
                                                            item.themeItemList.map((el, index) => {
                                                                return (
                                                                    <li key={index}>
                                                                        <img src={iconHotUrl}
                                                                             className={style['icon-hot']}/>
                                                                        <a className={style['hot-title']} target="_blank"
                                                                           href={this.props.state.pageUrl[item.resume] + "/article?id=" + el.id}
                                                                           title={el.title}>
                                                                            {el.title.length < 16 ? el.title : el.title.substring(0, 16) + '...'}
                                                                        </a>

                                                                        <span
                                                                            className={style['sp-time']}>{el.createTime.substring(0, 10)}</span>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </li>
                                            )
                                        }
                                        return null
                                    })
                                }
                                {
                                    newOnline?.length > 0 ? (
                                        newOnline.map((item, index) => {
                                            if (index === 0) {
                                                return (
                                                    <li key={index}
                                                        className={style['panel-box'] + ' ' + style['panel-box-1']}>
                                                        <h5 className={style['header']}>
                                                            <span className={style['panel-title']}>{item.name}</span>
                                                        </h5>
                                                        <ul>
                                                            {
                                                                item.lastUpdateItems.map((el, index) => {
                                                                    return (
                                                                        <li key={index}>
                                                                            <span className={style['list-dot']}></span>
                                                                            <a title={el.name_zh} target={'_blank'}
                                                                               onClick={() => this.toResult(item, el)}>{el.name_zh}</a>
                                                                            <span className={style['sp-time']}>{el.years}</span>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </li>
                                                )
                                            }
                                            return null
                                        })
                                    ) : (
                                        <li className={style['panel-box'] + ' ' + style['panel-box-1']}>
                                            <h5 className={style['header']}>
                                                <span className={style['panel-title']}>统计资料更新</span>
                                            </h5>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                    <div className={style['bg-03']}/>
                </div>
            </div>
        );
    }
}

//需要渲染什么数据
function mapStateToProps(state) {
    return {
        state: state.home,
        stateHeader: state.header
    }
}

//需要触发什么行为
function mapDispatchToProps(dispatch) {
    return {
        getVersion: () => dispatch(action.getVersion()),
        getBannerList: () => dispatch(action.getBannerPicList()),
        retValue: (key, value) => dispatch(action.retValue(key, value)),
        getDataPublish: (data) => dispatch(action.getDataPublish(data)),
        getNewOnline: () => dispatch(action.getNewOnline()),
        getThemesList: () => dispatch(action.getThemesList()),
        addUserAdvice: () => dispatch(action.addUserAdvice()),
        getDataCount: () => dispatch(action.getDataCount()),
        getLoginStatus: () => dispatch(action.getLoginStatus()),
        handleContactModal: (key, value) => dispatch(actionHeader.handleKeyValue(key, value)),
        handleIsAddUserAdviceSuccess: (key, value) => dispatch(actionHeader.handleKeyValue(key, value)),
        handleIndicateList: (key, value) => dispatch(action.retValue(key, value)),
        getIndicateList: (data) => dispatch(action.getIndicateList(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeOverseas)


